import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { getAuth, connectAuthEmulator } from "firebase/auth";

import {getFunctions} from 'firebase/functions'
import { httpsCallable, connectFunctionsEmulator  } from 'firebase/functions';
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";
import { connectFirestoreEmulator, getFirestore } from 'firebase/firestore';


// Your web app's Firebase configuration
const config = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN ,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID ,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET ,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID ,
  appId: process.env.REACT_APP_FIREBASE_APP_ID ,
};


export const app = firebase.initializeApp(config);

//if(process.env.NODE_ENV === "production")
//{
//    const appCheck = initializeAppCheck(app, {
//      provider: new ReCaptchaV3Provider(),
//      isTokenAutoRefreshEnabled: true
//    });
//}

export const auth = getAuth(app);
if(process.env.NODE_ENV !== "production")
{
  connectAuthEmulator(auth, "http://localhost:9099");
}

export const db = getFirestore(app);
if(process.env.NODE_ENV !== "production")
{
  connectFirestoreEmulator(db, "localhost", 8080);
}

const func = getFunctions(app);
if(process.env.NODE_ENV !== "production")
{
  connectFunctionsEmulator(func, "localhost", 5001);
}

//if(process.env.NODE_ENV !== "production")
//{
  //connectFunctionsEmulator(func, "localhost", 5001);
//}
export const newUser = httpsCallable(func,'newUser')
export const joinCall = httpsCallable(func,'joinCall')

export const joinCallDebug = httpsCallable(func,'joinCallDebug')
export const endCall = httpsCallable(func,'endCall')
export const endCallDebug = httpsCallable(func,'endCallDebug')
export const addTopic = httpsCallable(func, 'addTopic')
 
//Questions and answers management
export const addAnswerBackend = httpsCallable(func, 'addAnswer')
export const delAnswerBackend = httpsCallable(func, 'delAnswer')
export const editAnswerBackend = httpsCallable(func, 'editAnswer')
export const flagQuestionAsAnsweredBackend = httpsCallable(func, 'flagQuestionAsAnswered')
export const sintetizeAnswersBackend = httpsCallable(func, 'sintetizeAnswers')
export const addQuestionBackend = httpsCallable(func, 'addQuestion')
export const delQuestionBackend = httpsCallable(func, 'delQuestion')
export const createMeetingNotesBackend = httpsCallable(func, 'createMeetingNotes')
export const createASISBackend = httpsCallable(func, 'createASIS')
export const addMemberBackend = httpsCallable(func, 'addMembers')
export const getMembersBackend = httpsCallable(func, 'getMembers')
export const getSignedUrlBackend = httpsCallable(func, 'getSignedUrl')