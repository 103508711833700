import React from "react";

import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import ASISViewer from "./ASISViewer";


export class ASIS extends React.Component
{
    constructor(props) {
        super(props);
      }
    

    render() {
        return(<>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                        <ASISViewer uid={this.props.uid} projectSelected = {this.props.projectSelected}/>
                        </Paper>
                    </Grid>                
                </Grid>
        </>)
    };
}