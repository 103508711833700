import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { TextField, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Select, MenuItem, IconButton } from '@mui/material';
import { collection, query, where, onSnapshot, getDocs, getDoc, doc} from 'firebase/firestore';
import { db, joinCall,joinCallDebug, endCall, createMeetingNotesBackend, getSignedUrlBackend } from '../Connectors/firebase';
import  TopicStatus from '../Utils/TopicStatus';
import { Stack } from '@mui/material';
import { Download } from '@mui/icons-material';


export default function MeetingNotesContent(props) {
  const [meetings, setMeetings] = useState([]);

  const createMeetingNotes = async (meetingId) => {
    const result = await createMeetingNotesBackend({ callId: meetingId,uid: props.uid, pid: props.projectSelected.pid, oid: props.projectSelected.oid });
    if (result.data.success) {

    }
  };

    useEffect(() => {
    let unsubscribe;
    if(props.projectSelected.linked)
    {
      const q = query(collection(db, 'users', props.projectSelected.oid, 'projects', props.projectSelected.pid, 'meetings'), where('onGoing', '==', false));
      
      unsubscribe = onSnapshot(q, querySnapshot => {
        const promises = querySnapshot.docs.map(async meeting => {
          try {
            const topicDoc = await getDoc(doc(db, 'users', props.projectSelected.oid, 'projects', props.projectSelected.pid, 'meetings', meeting.id, 'topic', meeting.data().mainTopicId));
            
            if (topicDoc.exists()) {
              const topicData = topicDoc.data();
              console.log(topicData.topic.name);
              return { id: meeting.id, ...meeting.data(), topicName: topicData.topic.name };
            } else {
              console.error("Topic doc does not exist");
            }
          } catch (error) {
            console.error("An error occurred: ", error);
          }
        });
    
        Promise.all(promises)
          .then(meetingsData => {
            setMeetings(meetingsData); // Removes any undefined values
          })
          .catch(error => console.error("An error occurred when resolving promises: ", error));
      });
    }
    else
    {
      const q = query(collection(db, 'users', props.uid, 'projects', props.projectSelected.pid, 'meetings'), where('onGoing', '==', false));
    
      unsubscribe = onSnapshot(q, querySnapshot => {
        const promises = querySnapshot.docs.map(async meeting => {
          try {
            const topicDoc = await getDoc(doc(db, 'users', props.uid, 'projects', props.projectSelected.pid, 'meetings', meeting.id, 'topic', meeting.data().mainTopicId));
            
            if (topicDoc.exists()) {
              const topicData = topicDoc.data();
              console.log(topicData.topic.name);
              return { id: meeting.id, ...meeting.data(), topicName: topicData.topic.name };
            } else {
              console.error("Topic doc does not exist");
            }
          } catch (error) {
            console.error("An error occurred: ", error);
          }
        });
    
        Promise.all(promises)
          .then(meetingsData => {
            setMeetings(meetingsData); // Removes any undefined values
          })
          .catch(error => console.error("An error occurred when resolving promises: ", error));
      });
    }
  
    return () => unsubscribe();
  
  }, [props.uid, props.projectSelected]);
  


  return (
    <div>
      <h1>Meeting Notes Generator</h1>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Meeting ID</TableCell>
              <TableCell>Topic</TableCell>
              <TableCell align="right">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
          {meetings.map((meeting) => (
              <React.Fragment key={meeting.id}>
                <TableRow>
                  <TableCell component="th" scope="row">
                    {meeting.id}
                  </TableCell>
                  <TableCell component="th" scope="row" align="left">
                    {meeting.topicName}
                  </TableCell>
                  <TableCell align="right">
                    <Stack direction="row" spacing={2}>
                      {meeting.meeting_notes?
                      (
                        <IconButton aria-label="delete" onClick={
                          //Download link
                          async () => 
                          {
                            try {
                              const {data} = await getSignedUrlBackend({filePath: meeting.meeting_notes});
                              const link = document.createElement('a');
                              link.href = data.url;
                              document.body.appendChild(link);
                              link.click();
                              document.body.removeChild(link);
                            }catch(error)
                            {
                              console.log('Error getting signed URL: ', error);
                            }
                          }
                        }>
                          <Download/>
                        </IconButton>
                      )
                      :
                      (<Button variant="contained" color="secondary" onClick={() => createMeetingNotes(meeting.id)}>Create Meeting Notes</Button>)
                    }
                      
                      
                    </Stack>
                  </TableCell>
                </TableRow>
                {/* Check if meeting has valid lastResults and render TopicStatus */}
                {meeting.newestResults && <TableRow>
                  <TableCell colSpan={2}>
                    <TopicStatus status={meeting.newestResults} />
                  </TableCell>
                </TableRow>}
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
