import React, {useEffect, useState} from 'react';
import {
  Grid,
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Card, 
  CardContent,
  TextField
} from '@material-ui/core';
import useMediaQuery from '@mui/material/useMediaQuery';

import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import AddBoxIcon from '@mui/icons-material/AddBox';
import EditIcon from '@mui/icons-material/Edit';

import DoneAllIcon from '@mui/icons-material/DoneAll';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import DeleteIcon from '@mui/icons-material/Delete';

import { styled } from '@mui/system';
import { Dialog, DialogTitle, IconButton, Input, Stack } from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import Divider from '@mui/material/Divider';
import { TextInput } from 'react-native';
import Button from '@mui/material/Button';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { useTheme } from '@mui/material/styles';
import { getDoc, getDocs, collection, onSnapshot } from 'firebase/firestore';
import { toast, ToastContainer } from 'react-toastify';
  
const SubtopicTitleStyle = styled(Typography)(({ theme }) => ({
    '&.subtopic-title': {
        margin: '0px',
        fontWeight: 600,
        fontSize: '1rem',
        lineHeight: 1.5,
        fontFamily: '"Public Sans", sans-serif',
        color: 'rgb(38, 38, 38)',
        marginBottom: '1rem',
        marginTop: '2rem',
    }
}));

const StyledCardContent = styled(CardContent)(({ theme }) => ({
    '&.subtopicCardContent': {
        paddingBottom: '0px !important',
        padding: '0px !important',
    }
}));
const StyledCard = styled(Card)(({ theme }) => ({

    '&.subtopicCard': {
        backgroundColor: 'white',
    }
}));




export default function SubtopicCard ({ subtopicData, addQuestion, delQuestion, editAnswer, delAnswer, answserQuestion, addAnswer, sintetizeAnswers }) { 
    const [answerDialogOpen, setAnswerDialogOpen] = useState(false); // new state for dialog open/close
    const [questionDialogOpen, setQuestionDialogOpen] = useState(false); // new state for dialog open/close

    const [answerReferenceQuestion, setAnswerReferenceQuestion] = useState(''); // new state for dialog title
    const [answerReferenceSubtopic, setAnswerReferenceSubtopic] = useState(''); // new state for dialog title
    const [questionReferenceSubtopic, setQuestionReferenceSubtopic] = useState(''); // new state for dialog title
    const [questionId, setQuestionId] = useState(''); // new state for dialog title
    const [dialogQuestionContent, setDialogQuestionContent] = useState(''); // new state for dialog title
    const [questions, setQuestions] = useState(undefined); // new state for dialog title
    const [answerId, setAnswerId] = useState(''); // new state for dialog title

    const delQuestionLocal = async (questionId) => {
        delQuestion(questionId,subtopicData.subtopicId).then(async (result) => {
            if(result && result.data?.success === true)
            {
                //Need to update the question answers in questions variable by using setQuestions
                try {
                    const subtopicDoc = await getDoc(subtopicData.ref);
                    if (subtopicDoc.exists()) {
                        const questionsRef = collection(subtopicData.ref, 'Questions');
                        const questionsSnapshot = await getDocs(questionsRef);
                        const questionsTemp = await Promise.all(questionsSnapshot.docs.map(async (doc) => {
                        const answersRef = collection(doc.ref, 'Answers');
                        const answersSnapshot = await getDocs(answersRef);
                        const answersTemp = answersSnapshot.docs.map((answerDoc) => ({
                            id: answerDoc.id, ...answerDoc.data(), ref: answerDoc.ref
                        }));
                        return {
                            id: doc.id,
                            ...doc.data(),
                            ref: doc.ref,
                            answers: answersTemp
                        };
                    }));
                    setQuestions(questionsTemp);
                } else {
                    console.log("No such document!");
                }
            } catch (error) {
                console.log("Error getting document:", error);
            }                      

        }
        });
    }

    const addQuestionLocal = async (questionString) => {
        addQuestion(questionString,subtopicData.subtopicId).then(async (result) => {
            if(result && result.data?.success === true)
            {
                //Need to update the question answers in questions variable by using setQuestions
                try {
                    const subtopicDoc = await getDoc(subtopicData.ref);
                    if (subtopicDoc.exists()) {
                        const questionsRef = collection(subtopicData.ref, 'Questions');
                        const questionsSnapshot = await getDocs(questionsRef);
                        const questionsTemp = await Promise.all(questionsSnapshot.docs.map(async (doc) => {
                        const answersRef = collection(doc.ref, 'Answers');
                        const answersSnapshot = await getDocs(answersRef);
                        const answersTemp = answersSnapshot.docs.map((answerDoc) => ({
                            id: answerDoc.id, ...answerDoc.data(), ref: answerDoc.ref
                        }));
                        return {
                            id: doc.id,
                            ...doc.data(),
                            ref: doc.ref,
                            answers: answersTemp
                        };
                    }));
                    setQuestions(questionsTemp);
                } else {
                    console.log("No such document!");
                }
            } catch (error) {
                console.log("Error getting document:", error);
            }                      

        }
        });
    }


    const sintetizeAnswersLocal = async (questionId) => {
        sintetizeAnswers(questionId,subtopicData.subtopicId).then(async (result) => {
            if(result && result.data?.success === true)
            {
                //Need to update the question answers in questions variable by using setQuestions
                try {
                    const subtopicDoc = await getDoc(subtopicData.ref);
                    if (subtopicDoc.exists()) {
                        const questionsRef = collection(subtopicData.ref, 'Questions');
                        const questionsSnapshot = await getDocs(questionsRef);
                        const questionsTemp = await Promise.all(questionsSnapshot.docs.map(async (doc) => {
                        const answersRef = collection(doc.ref, 'Answers');
                        const answersSnapshot = await getDocs(answersRef);
                        const answersTemp = answersSnapshot.docs.map((answerDoc) => ({
                            id: answerDoc.id, ...answerDoc.data(), ref: answerDoc.ref
                        }));
                        return {
                            id: doc.id,
                            ...doc.data(),
                            ref: doc.ref,
                            answers: answersTemp
                        };
                    }));
                    setQuestions(questionsTemp);
                } else {
                    console.log("No such document!");
                }
            } catch (error) {
                console.log("Error getting document:", error);
            }                      

        }
        });
    }



    const answerQuestionLocal = async (questionId) => {

        //check that quesion has answers
        let questionHasAnswers = false;
        let questionStatus = "pending";
        questions.forEach((q) => {
            if(q.id === questionId && q.answers.length > 0)
            {
                questionHasAnswers = true;
            }
            if(q.id ===questionId)
            {
                questionStatus = q.status
            }
        });

        if(!questionHasAnswers && questionStatus !== "answered")
        {
            toast.warn("You need to add at least one answer to the question before flagging it as answered.",{
                position: toast.POSITION.TOP_RIGHT
            })
            return;
        }


        answserQuestion(questionId,subtopicData.subtopicId).then(async (result) => {
            if(result && result.data?.success === true)
            {
                //Need to update the question answers in questions variable by using setQuestions
                try {
                    const subtopicDoc = await getDoc(subtopicData.ref);
                    if (subtopicDoc.exists()) {
                        const questionsRef = collection(subtopicData.ref, 'Questions');
                        const questionsSnapshot = await getDocs(questionsRef);
                        const questionsTemp = await Promise.all(questionsSnapshot.docs.map(async (doc) => {
                        const answersRef = collection(doc.ref, 'Answers');
                        const answersSnapshot = await getDocs(answersRef);
                        const answersTemp = answersSnapshot.docs.map((answerDoc) => ({
                            id: answerDoc.id, ...answerDoc.data(), ref: answerDoc.ref
                        }));
                        return {
                            id: doc.id,
                            ...doc.data(),
                            ref: doc.ref,
                            answers: answersTemp
                        };
                    }));
                    setQuestions(questionsTemp);
                } else {
                    console.log("No such document!");
                }
            } catch (error) {
                console.log("Error getting document:", error);
            }                      

        }
        });
    }


    const delAnswerLocal = async (answerId, questionId ) => {
        delAnswer(answerId,subtopicData.subtopicId,questionId).then(async (result) => {
            if(result && result.data?.success === true)
            {
                //Need to update the question answers in questions variable by using setQuestions
                try {
                    const subtopicDoc = await getDoc(subtopicData.ref);
                    if (subtopicDoc.exists()) {
                        const questionsRef = collection(subtopicData.ref, 'Questions');
                        const questionsSnapshot = await getDocs(questionsRef);
                        const questionsTemp = await Promise.all(questionsSnapshot.docs.map(async (doc) => {
                            const answersRef = collection(doc.ref, 'Answers');
                            const answersSnapshot = await getDocs(answersRef);
                            const answersTemp = answersSnapshot.docs.map((answerDoc) => ({
                                id: answerDoc.id, ...answerDoc.data(), ref: answerDoc.ref
                            }));
                            return {
                                id: doc.id,
                                ...doc.data(),
                                ref: doc.ref,
                                answers: answersTemp
                            };
                        }));
                        setQuestions(questionsTemp);
                    } else {
                        console.log("No such document!");
                    }
                } catch (error) {
                    console.log("Error getting document:", error);
                }                      

            }
        });
    }

    const addQuestionDialog = () =>{
        setQuestionDialogOpen(false); 

        addQuestion(dialogQuestionContent, questionReferenceSubtopic).then(async (result) => {
            if(result && result.data?.success === true)
            {
                //Need to update the question answers in questions variable by using setQuestions
                try {
                    const subtopicDoc = await getDoc(subtopicData.ref);
                    if (subtopicDoc.exists()) {
                        const questionsRef = collection(subtopicData.ref, 'Questions');
                        const questionsSnapshot = await getDocs(questionsRef);
                        const questionsTemp = await Promise.all(questionsSnapshot.docs.map(async (doc) => {
                            const answersRef = collection(doc.ref, 'Answers');
                            const answersSnapshot = await getDocs(answersRef);
                            const answersTemp = answersSnapshot.docs.map((answerDoc) => ({
                                id: answerDoc.id, ...answerDoc.data(), ref: answerDoc.ref
                            }));
                            return {
                                id: doc.id,
                                ...doc.data(),
                                ref: doc.ref,
                                answers: answersTemp
                            };
                        }));
                        setQuestions(questionsTemp);
                    } else {
                        console.log("No such document!");
                    }
                } catch (error) {
                    console.log("Error getting document:", error);
                }                      

            }
            
        });
        setDialogQuestionContent('');
    }

    const addAnswerDialog = () =>{
        setAnswerDialogOpen(false); 

        addAnswer(dialogQuestionContent, answerReferenceSubtopic, answerReferenceQuestion).then(async (result) => {
            if(result && result.data?.success === true)
            {
                //Need to update the question answers in questions variable by using setQuestions
                try {
                    const subtopicDoc = await getDoc(subtopicData.ref);
                    if (subtopicDoc.exists()) {
                        const questionsRef = collection(subtopicData.ref, 'Questions');
                        const questionsSnapshot = await getDocs(questionsRef);
                        const questionsTemp = await Promise.all(questionsSnapshot.docs.map(async (doc) => {
                            const answersRef = collection(doc.ref, 'Answers');
                            const answersSnapshot = await getDocs(answersRef);
                            const answersTemp = answersSnapshot.docs.map((answerDoc) => ({
                                id: answerDoc.id, ...answerDoc.data(), ref: answerDoc.ref
                            }));
                            return {
                                id: doc.id,
                                ...doc.data(),
                                ref: doc.ref,
                                answers: answersTemp
                            };
                        }));
                        setQuestions(questionsTemp);
                    } else {
                        console.log("No such document!");
                    }
                } catch (error) {
                    console.log("Error getting document:", error);
                }                      

            }
            
        });
        setAnswerId(null);
        setDialogQuestionContent('');
    }

    const editAnswerDialog = () =>{
        setAnswerDialogOpen(false);
        editAnswer(dialogQuestionContent, answerId, answerReferenceSubtopic, answerReferenceQuestion).then(async (result) => {
            if(result && result.data?.success === true)
            {
                //Need to update the question answers in questions variable by using setQuestions
                try {
                    const subtopicDoc = await getDoc(subtopicData.ref);
                    if (subtopicDoc.exists()) {
                        const questionsRef = collection(subtopicData.ref, 'Questions');
                        const questionsSnapshot = await getDocs(questionsRef);
                        const questionsTemp = await Promise.all(questionsSnapshot.docs.map(async (doc) => {
                            const answersRef = collection(doc.ref, 'Answers');
                            const answersSnapshot = await getDocs(answersRef);
                            const answersTemp = answersSnapshot.docs.map((answerDoc) => ({
                                id: answerDoc.id, ...answerDoc.data(), ref: answerDoc.ref
                            }));
                            return {
                                id: doc.id,
                                ...doc.data(),
                                ref: doc.ref,
                                answers: answersTemp
                            };
                        }));
                        setQuestions(questionsTemp);
                    } else {
                        console.log("No such document!");
                    }
                } catch (error) {
                    console.log("Error getting document:", error);
                }                      

            }
        });
        
        setAnswerId(null);
        setDialogQuestionContent('');
        
    }

    const handleClose = () => {
        setAnswerDialogOpen(false);
        setQuestionDialogOpen(false);
        setDialogQuestionContent('');
    };


    const openAnswerDialog = (open, refSubtopic, refQuestion, answer=null ) => {
        let callbackStruct=null;

        if(!answer)
        {
            setAnswerReferenceQuestion(refQuestion);
            setAnswerReferenceSubtopic(refSubtopic);
            setAnswerDialogOpen(open);
        }
        else
        {
          
            setAnswerId(answer.id);
            setAnswerReferenceQuestion(refQuestion);
            setAnswerReferenceSubtopic(refSubtopic);
            setDialogQuestionContent(answer.content);
            setAnswerDialogOpen(open);
        }

    }

    const openQuestionDialog = (open, refSubtopic, question=null ) => {
        let callbackStruct=null;

        if(!question)
        {
            setQuestionReferenceSubtopic(refSubtopic);
            setQuestionDialogOpen(open);
        }
        else
        {
            setQuestionReferenceSubtopic(refSubtopic);
            setDialogQuestionContent(question.content);
            setQuestionDialogOpen(open);
        }

    }


    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));


    useEffect(() => {
        if (!subtopicData) return;
    
        const fetchQuestionsAndAnswers = async () => {
            try {
                const subtopicDoc = await getDoc(subtopicData.ref);
                if (subtopicDoc.exists()) {
                    const questionsRef = collection(subtopicData.ref, 'Questions');
                    const questionsSnapshot = await getDocs(questionsRef);
                    const questionsTemp = await Promise.all(questionsSnapshot.docs.map(async (doc) => {
                        const answersRef = collection(doc.ref, 'Answers');
                        const answersSnapshot = await getDocs(answersRef);
                        const answersTemp = answersSnapshot.docs.map((answerDoc) => ({
                            id: answerDoc.id, ...answerDoc.data(), ref: answerDoc.ref
                        }));
                        return {
                            id: doc.id,
                            ...doc.data(),
                            ref: doc.ref,
                            answers: answersTemp
                        };
                    }));
                    setQuestions(questionsTemp);
                } else {
                    console.log("No such document!");
                }
            } catch (error) {
                console.log("Error getting document:", error);
            }
        };
    
        fetchQuestionsAndAnswers();
    }, [subtopicData]);

  

    useEffect(() => {
        if (!subtopicData) return;
    
        const unsubscribe = onSnapshot(subtopicData.ref, async (subtopicDoc) => {
            if (subtopicDoc.exists()) {
                const questionsRef = collection(subtopicData.ref, 'Questions');
                try {
                    const questionsSnapshot = await getDocs(questionsRef);
                    const questionsTemp = await Promise.all(questionsSnapshot.docs.map(async (doc) => {
                        const answersSnapshot = await getDocs(collection(doc.ref, 'Answers'));
                        const answersTemp = answersSnapshot.docs.map((answerDoc) => ({
                            id: answerDoc.id, ...answerDoc.data(), ref: answerDoc.ref
                        }));
                        return {
                            id: doc.id,
                            ...doc.data(),
                            ref: doc.ref,
                            answers: answersTemp
                        };
                    }));
                    setQuestions(questionsTemp);
                } catch (error) {
                    console.log("Error getting document:", error);
                }
            } else {
                console.log("No such document!");
            }
        });
    
        return () => unsubscribe();
    }, [subtopicData]);
  


    if(!subtopicData) return null;

    return (
    <>
    <ToastContainer />

    <Dialog
        fullScreen={fullScreen}
        open={answerDialogOpen}
        onClose={handleClose}
        aria-labelledby="custom-answer-dialog"
      >
        <DialogTitle id="custom-answer-dialog">
            {answerId ? "Edit answer" : "Add answer"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Give as much detail as possible to answer the question of the subtopic. The answer will be analized by the AI to see if more information is needed or not. You can always flag it as answered.
            <TextField
                autoFocus
                margin="dense"
                id="name"
                type="text"
                fullWidth
                multiline
                maxRows={4}
                placeholder="Write your answer here..."
                value={dialogQuestionContent===""? "": dialogQuestionContent}
                onChange={(event) => {
                    setDialogQuestionContent(event.target.value);
                  }}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Cancel
          </Button>
          <Button onClick={() => {
                if (!answerId) {
                addAnswerDialog();
                } else {
                editAnswerDialog();
                }
            }}
          autoFocus>
            {answerId ? "Edit" : "Add"}
          </Button>
        </DialogActions>
    </Dialog>

    <Dialog
        fullScreen={fullScreen}
        open={questionDialogOpen}
        onClose={handleClose}
        aria-labelledby="custom-question-dialog"
      >
        <DialogTitle id="custom-question-dialog">
            Add Question
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Add a new question. Be concise and precise at the same time.
            <TextField
                autoFocus
                margin="dense"
                id="name"
                type="text"
                fullWidth
                multiline
                maxRows={4}
                placeholder="Write your answer here..."
                value={dialogQuestionContent===""? "": dialogQuestionContent}
                onChange={(event) => {
                    setDialogQuestionContent(event.target.value);
                  }}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Cancel
          </Button>
          <Button onClick={() => {
                addQuestionDialog();
            }}
          autoFocus>
            Add
          </Button>
        </DialogActions>
    </Dialog>

        <SubtopicTitleStyle className="subtopic-title" variant="h5"> {subtopicData.label}</SubtopicTitleStyle>
        

        <Grid item xs={12} md={12} lg={12}>
            <StyledCard className='subtopicCard'>
            <StyledCardContent className="subtopicCardContent">
                <Table>
                <TableHead>
                    <TableRow>
                    <TableCell>Question</TableCell>
                    <TableCell>Answers</TableCell>                    
                    <TableCell>Status</TableCell>                                       
                    <TableCell>Actions</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {questions ? (questions.map((q, qIndex) => (
                    <>
                        <TableRow key={qIndex}>
                            <TableCell>{q.question}</TableCell>
                            <TableCell>
                                <Stack direction="column" spacing={1} alignItems="center">
                                    {q.answers.map((a, aIndex) => (
                                        <>
                                            <Stack direction="row" spacing={1} alignItems="center">
                                                <Typography>{a.answer}</Typography>
                                                <IconButton 
                                                sx={{
                                                    color: 'rgb(140, 140, 140)',
                                                    '&:hover':{backgroundColor:'rgb(240, 240, 240)'}
                                                }} 
                                                aria-label="Delete"
                                                onClick={()=> delAnswerLocal(a.id,q.id)}
                                                >
                                                    <ThumbDownIcon />
                                                </IconButton>
                                                <IconButton 
                                                sx={{
                                                    color: 'rgb(140, 140, 140)',
                                                    '&:hover':{backgroundColor:'rgb(240, 240, 240)'}
                                                }} 
                                                aria-label="Edit"
                                                onClick={() => openAnswerDialog(true,subtopicData.id, q.id,{content: a.answer, id: a.id} )}
                                                >
                                                    <EditIcon />
                                                </IconButton>
                                            </Stack>
                                            <Divider light variant='middle'/>
                                        </>
                                    ))}
                                        <IconButton 
                                            sx={{
                                                color: 'rgb(140, 140, 140)',
                                                '&:hover':{backgroundColor:'rgb(240, 240, 240)'}
                                            }} 
                                            aria-label="Add"
                                            onClick={() => openAnswerDialog(true,subtopicData.id, q.id )}
                                        >
                                            <AddBoxIcon />
                                        </IconButton>
                                </Stack>
                            </TableCell>                        
                            <TableCell>
                                <Stack direction="row" spacing={1} alignItems="center">
                                <Box style={{
                                    backgroundColor: q.status === "answered" ? "#52c41a" : q.status === "pending" ? "#ff4d4f" : "#faad14",
                                    width: "8px",
                                    height: "8px",
                                    borderRadius: "50%",
                                }}></Box>
                                <Typography></Typography>
                                </Stack>
                            </TableCell>
                            <TableCell>
                                <Stack direction="row" spacing={1} alignItems="center">
                                    {/* Flag as answered */}
                                <IconButton 
                                    sx={{
                                        
                                        color: q.status==='answered'? 'rgb(255, 77, 79)':'rgb(82, 196, 26)',
                                        '&:hover':{backgroundColor: q.status==='answered'? 'rgb(255, 241, 240)':'rgb(246, 255, 237)'}
                                    }} 
                                    aria-label="Flag or unflag as answered"
                                    onClick={()=> answerQuestionLocal(q.id)}
                                    >
                                        <DoneAllIcon />
                                </IconButton>
                                {/* Create summary */}
                                <IconButton 
                                sx={{
                                    color: 'rgb(24, 144, 255)',
                                    '&:hover':{backgroundColor:'rgb(230, 247, 255)'}
                                }} 
                                aria-label="Summarize"
                                onClick={()=> sintetizeAnswersLocal(q.id)}
                                >
                                    <AutoFixHighIcon />
                                </IconButton>
                                {/* Delete */}
                                <IconButton 
                                sx={{
                                    color: 'rgb(255, 77, 79)',
                                    '&:hover':{backgroundColor:'rgb(255, 241, 240)'}
                                }} 
                                aria-label="Delete"
                                onClick={()=> delQuestionLocal(q.id)}
                                >
                                    <DeleteIcon />
                                </IconButton>
                                </Stack>
                            </TableCell>
                        </TableRow>
                    </>
                    ))):(
                        <TableRow key="999999">
                            {/* Create a dynamic animation loading cell */}   
                            <TableCell colSpan={4}>
                                <Box sx={{
                                        width: '100%',
                                        height: '32px',
                                        animation: 'fadeinout 3s infinite'
                                       
                                    }}></Box>
                                   
                                    <Typography></Typography>
                            </TableCell>
                        </TableRow>
                    )}
                    
                    {questions?(
                    <TableRow key={96969}>
                            <TableCell colSpan={4}>
                                <Stack direction="row" spacing={1} alignItems="center">
                                    <Button 
                                    variant="contained" 
                                    color="primary" 
                                    onClick={() => openQuestionDialog(true,subtopicData.id)}
                                    >
                                        Add question
                                    </Button>
                                </Stack>
                            </TableCell>
                        </TableRow>
                        ):((null))}
                </TableBody>
                </Table>
            </StyledCardContent>
            </StyledCard>
            
        </Grid>
        
    </>
  );
}
