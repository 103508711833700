import React, { useEffect, useState, useRef} from "react";
import { app } from "../Connectors/firebase";
import { doc, query, onSnapshot, getFirestore, connectFirestoreEmulator, setDoc, deleteDoc,collection} from "firebase/firestore"


import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';
import { Box, IconButton } from "@mui/material";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Title from '../Utils/Title';


import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';

import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import DoneIcon from '@mui/icons-material/Done';
import FormDialog from "../Utils/PopupDialog";

import MultiLingualContent from '../Utils/MultilingualContent'
import MediaQuery from "react-responsive";

import { createASISBackend } from "../Connectors/firebase";




const db = getFirestore(app);

/*if(process.env.NODE_ENV !== "production")
{
  connectFirestoreEmulator(db, 'localhost', 8080);
}*/

export default function ASISViewer(props)
{
    const [projectList, setprojectList] = useState([]);


    const ProjectMobile =((props)=>{
        return(
            <>
                
            </>
        );
    })

    const createASIS = async () => {
        const result = await createASISBackend({ pid: props.projectSelected.pid, oid: props.projectSelected.oid});
        if (result.data.success) {
            console.log("ASIS created");
        }
    }


    function Projects(rows) {
        return (
          <React.Fragment>
            <Title><MultiLingualContent contentID="project"/></Title>
            <MediaQuery minWidth={600}>

            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                    <TableRow >
                        <TableCell>
                            <IconButton color="primary" aria-label={<MultiLingualContent contentID="edit"/>} component="span" onClick={()=>createASIS(props.projectSelected)}>
                                <EditIcon />
                            </IconButton>
                        </TableCell>
                    </TableRow>
              </TableBody>
            </Table>
            
            </MediaQuery>
            {/*Phone and tablets */}
            <MediaQuery maxWidth={600}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Actions</TableCell>
                
                </TableRow>
              </TableHead>
              <TableBody>
                    <TableRow>
                        <TableCell>
                            <IconButton color="primary" aria-label={<MultiLingualContent contentID="edit"/>} component="span" onClick={()=>createASIS(props.projectSelected)}>
                                <EditIcon />
                            </IconButton>
                        </TableCell>
                    </TableRow>
              </TableBody>
            </Table>
            </MediaQuery>
          </React.Fragment>
        );
      }


    
    return(
    <>{Projects(projectList)}</>)
} 
