import React from "react";


import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';

import MeetingDashboard from './meetingDashboard';


export class Meeting extends React.Component
{
       constructor(props) {
        super(props);
    }
    
    render() {
        console.log("Meeting");
        return(<>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <MeetingDashboard uid={this.props.uid} projectList={this.props.projectList} projectSelected={this.props.projectSelected} sectionSelectorCB={this.props.sectionSelectorCB} args = {this.props.args}  projectSelectorFunction ={this.props.projectSelectorFunction}/>
                </Grid>                
            </Grid>
        </>)
    };
}