import React from 'react';
import { Card, CardContent, Typography, Grid, Chip, Box } from '@mui/material';
import { styled } from '@mui/system';

const TitleStyle = styled(Typography)(({ theme }) => ({
  '&.info-card-title': {
    '-webkit-font-smoothing': 'antialiased',
    'text-size-adjust': '100%',
    'box-sizing': 'inherit',
    margin: '0px',
    fontWeight: 400,
    fontSize: '0.875rem',
    lineHeight: 1.57,
    fontFamily: '"Public Sans", sans-serif',
    color: 'rgb(140, 140, 140)',
    }
}));

const ValueStyle = styled(Typography)(({ theme }) => ({
  '&.valueStyleInfoCard': {
  '-webkit-font-smoothing': 'antialiased',
  'text-size-adjust': '100%',
  'box-sizing': 'inherit',
  margin: '0px',
  fontWeight: 600,
  fontSize: '1.25rem',
  lineHeight: 1.4,
  fontFamily: '"Public Sans", sans-serif',
  color: 'inherit',}
}));

const PercentageStyle = styled(Typography)(({ theme }) => ({
  '&.percentageStyleInfoCard': {
    '-webkit-font-smoothing': 'antialiased',
    'text-size-adjust': '100%',
    lineHeight: 1.57,
    fontWeight: 400,
    fontFamily: '"Public Sans", sans-serif',
    fontSize: '0.8125rem',
    color: 'rgb(255, 255, 255)',
    cursor: 'default',
    'box-sizing': 'inherit',
    overflow: 'hidden',
    'text-overflow': 'ellipsis',
    paddingLeft: '8px',
    paddingRight: '8px',
    'white-space': 'nowrap',
  }
}));

const ExtraStyle = styled(Typography)(({ theme }) => ({
  '&.extraStyleInfoCard': {
    '-webkit-font-smoothing': 'antialiased',
    'text-size-adjust': '100%',
    'box-sizing': 'inherit',
    margin: '0px',
    fontWeight: 400,
    fontSize: '0.75rem',
    lineHeight: 1.66,
    fontFamily: '"Public Sans", sans-serif',
    color: 'rgb(140, 140, 140)',
  }
}));


const InfoCard = ({ title, value, percentage, extra, icon }) => {
  return (
    <Card>
      <CardContent>
        <Grid container direction="column">
          <TitleStyle className="info-card-title" variant="h6">{title}</TitleStyle>
          <Grid container justifyContent="space-between">
            <Grid item>
              <ValueStyle className="valueStyleInfoCard" variant="h4">{value}</ValueStyle>
            </Grid>
            {/**
            <Grid item>
              <PercentageStyle className="percentageStyleInfoCard" icon={icon} label={`${percentage}%`} />
            </Grid>*/}
          </Grid> 
          {/** <Box mt={2}>
            <ExtraStyle className="extraStyleInfoCard" variant="caption">
              You made an extra <strong>{extra}</strong> this year
            </ExtraStyle>
          </Box>*/}
          
        </Grid>
      </CardContent>
    </Card>
  );
};

export default InfoCard;
