import * as React from 'react';
import Divider from '@mui/material/Divider';

import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import SpeakerNotesIcon from '@mui/icons-material/SpeakerNotes';
import DashboardIcon from '@mui/icons-material/Dashboard';
import LogoutIcon from '@mui/icons-material/Logout';
import BookIcon from '@mui/icons-material/Book';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';

import { getAuth, signOut } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import MultiLingualContent from '../Utils/MultilingualContent'
import RateReviewIcon from '@mui/icons-material/RateReview';

const auth = getAuth();

const logout = (navigate) =>{

  signOut(auth).then(() => {

    window.location.reload();
}).catch((error) => {
    console.log("Could not log out...");
});
}



export default function GetMenu (props){


  const navigate = useNavigate();

  
  return(<>    
    <React.Fragment>
      <ListItemButton onClick={()=>{props.cb("Dashboard"); if(props.cbClose){props.cbClose()}}}>
        <ListItemIcon>
          <DashboardIcon />
        </ListItemIcon>
        <ListItemText primary={<MultiLingualContent contentID="dashboard_btn"/>} />
      </ListItemButton>
      <ListItemButton onClick={()=>{props.cb("Alcance"); if(props.cbClose){props.cbClose()}}}>
        <ListItemIcon>
          <FormatListNumberedIcon />
        </ListItemIcon>
        <ListItemText primary={<MultiLingualContent contentID="alcance_btn"/>} />
      </ListItemButton>
      <ListItemButton onClick={()=>{props.cb("Actas"); if(props.cbClose){props.cbClose()}}}>
        <ListItemIcon>
          <SpeakerNotesIcon />
        </ListItemIcon>
        <ListItemText primary={<MultiLingualContent contentID="actas_btn"/>} />
      </ListItemButton>
      <ListItemButton onClick={()=>{props.cb("ASIS"); if(props.cbClose){props.cbClose()}}}>
        <ListItemIcon>
          <BookIcon />
        </ListItemIcon>
        <ListItemText primary={<MultiLingualContent contentID="asis_btn"/>} />
      </ListItemButton>
      <ListItemButton onClick={()=>{props.cb("TOBE"); if(props.cbClose){props.cbClose()}}}>
        <ListItemIcon>
          <NewReleasesIcon />
        </ListItemIcon>
        <ListItemText primary={<MultiLingualContent contentID="tobe_btn"/>} />
      </ListItemButton>     
    </React.Fragment>
    <Divider sx={{ my: 1 }} />
    <ListSubheader component="div" inset>
    <MultiLingualContent contentID="account"/>
    </ListSubheader>
    <ListItemButton onClick={()=>{props.cb("Topic"); if(props.cbClose){props.cbClose()}}}>
      <ListItemIcon>
        <RateReviewIcon />
      </ListItemIcon>
      <ListItemText primary={<MultiLingualContent contentID="add_topic"/>} />
    </ListItemButton>
    <ListItemButton onClick={() => logout(navigate)}>
      <ListItemIcon>
        <LogoutIcon />
      </ListItemIcon>
      <ListItemText primary={<MultiLingualContent contentID="log_out"/>} />
    </ListItemButton>
  </>
  )
}




