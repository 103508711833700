import React from 'react';
import { List, ListItem, ListItemText } from '@mui/material';
import { green, red } from '@mui/material/colors';

export default function TopicStatus({status}) {
  const parsedStatus = JSON.parse(status);

  return (
    <div>
      {Object.entries(parsedStatus.subtopics).map(([subtopicName, specificPoints]) => (
        <div key={subtopicName}>
          <h3>{subtopicName}</h3>
          {Object.entries(specificPoints).map(([pointName, pointData]) => {
            return (
              <div key={pointName}>
                <List>
                  <ListItem style={{ backgroundColor: pointData.status === 'Finalized' ? green[500] : red[500] }}>
                    <ListItemText primary={pointName} secondary={pointData.status} />
                  </ListItem>
                  {pointData.info && (
                    <ListItem>
                      <ListItemText primary="Info" secondary={pointData.info} />
                    </ListItem>
                  )}
                  {pointData.missing_info && pointData.missing_info.length > 0 && (
                    <ListItem>
                    <ListItemText 
                      primary="Missing Info" 
                      secondary={pointData.missing_info.map(item => typeof item === 'object' ? JSON.stringify(item) : item).join(', ')} 
                    />
                  </ListItem>
                  )}
                </List>
              </div>
            )
          })}
        </div>
      ))}
    </div>
  );
}
