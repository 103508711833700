// src/msalConfig.js
import { UserAgentApplication } from "msal";

const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_MICROSOFT_APP_ID,
    authority: `https://login.microsoftonline.com/${process.env.REACT_APP_MICROSOFT_TENANT_ID}`,
    //redirectUri: window.location.origin,
    redirectUri: 'https://dev-ditechai.firebaseapp.com/__/auth/handler'
  },
};

export const msalApp = new UserAgentApplication(msalConfig);

export const getUserPhoto = async (accessToken) => {
  const endpoint = "https://graph.microsoft.com/v1.0/me/photo/$value";
  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;

  headers.append("Authorization", bearer);

  const options = {
    method: "GET",
    headers: headers,
  };

  const response = await fetch(endpoint, options);
  const blob = await response.blob();
  return URL.createObjectURL(blob);
};