import * as React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';

import 'firebase/compat/auth';

import { AuthContext} from './Connectors/context';
import Layout from './Layout'
import { Navigate } from 'react-router-dom'
import { lightTheme } from './Utils/ThemeOptions';
import { darkTheme } from './Utils/ThemeOptions';

import MultiLingualContent from './Utils/MultilingualContent';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import MediaQuery from 'react-responsive';


export class Runtime extends React.Component
{
    static contextType = AuthContext;

    constructor(props) {
        super(props);
        this.state={
         open:true,
         preferredTheme: 'light',
        }
      }

      componentDidMount() {
        console.log(this.context.user);
        const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    
        const handleChangeTheme = (event) => {
          this.setState({
            preferredTheme: event.matches ? 'dark' : 'light',
          });
          
        };
    
        mediaQuery.addEventListener('change', handleChangeTheme);
        handleChangeTheme(mediaQuery); // Set initial theme based on user preference
      }
    
      componentWillUnmount() {
        const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
        mediaQuery.removeEventListener('change', this.handleChangeTheme);
      }
    
      toggleOpen = () => {
        this.setState({
          ...this.state,
          open: !this.state.open
        });
      };

      
  
    render() {

      const { preferredTheme } = this.state;

      if (this.context.user === undefined) {
        return <div>Loading...</div>;
      }

        return(
            <div>
                {!!this.context.user ? (   
                                                   
                  <ThemeProvider theme={preferredTheme === 'dark' ? darkTheme : lightTheme}>
                    <Box sx={{ display: 'flex' }}>
                      <CssBaseline />
                      {/* Render the rest of your app */}
                      <Layout uid={this.context.user.uid} email={this.context.user.email} section={this.props.section} />
                    </Box>
                  </ThemeProvider>
                   
              ) : (
                
             <Navigate to={{ pathname: "/"}} />
             )}
             </div>
        )
    };
}

