import React, { useState } from 'react';
import './firebaseui-styling.global.css'
import "firebase/auth";
import Paper from '@mui/material/Paper'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import MultiLingualContent from './Utils/MultilingualContent'
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import Typography from '@mui/material/Typography';
import { OAuthProvider } from "firebase/auth";
import { getAuth, signInWithPopup } from "firebase/auth";
import { generateCodeChallenge } from './Utils/CryptoFunctions';


const provider = new OAuthProvider('microsoft.com');
provider.setCustomParameters({
  tenant: process.env.REACT_APP_MICROSOFT_TENANT_ID,
  //client_id: process.env.REACT_APP_MICROSOFT_APP_ID,
  //response_type: 'token',
  //redirect_uri: 'https://dev-ditechai.firebaseapp.com/__/auth/handler',
  //scope: 'openid profile email',
  //code_challenge: generateCodeChallenge(),
  //code_challenge_method: 'S256',
  prompt: 'select_account',
  //response_type: 'code',
});

const auth = getAuth();

const Login = ({ setComponents }) => {

  const navigate = useNavigate();
  const handleSubmit = async (e) => {
    e.preventDefault();
    signInWithPopup(auth, provider)
    .then((result) => {
      // User is signed in.
      // IdP data available in result.additionalUserInfo.profile.

      // Get the OAuth access token and ID Token
      const credential = OAuthProvider.credentialFromResult(result);
      const accessToken = credential.accessToken;
      const idToken = credential.idToken;

      localStorage.setItem("msft_access_token", accessToken);


    })
    .catch((error) => {
        toast.error(`Error signing in`);
      });
  };

  return (
    <React.Fragment>
        <Paper elevation={3} sx=
        {{
          minWidth:300,     
        }}>
          <Typography component="div" align="center" // Center the text
            sx={{
              height: 20,
              color: '#654a86',
              fontWeight: 'normal',
              fontSize: 20,
              fontFamily: 'Roboto',
            }}
          >
            <MultiLingualContent contentID="sing_up_or_login" />
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: 1,
            bgcolor: 'background.paper',
            p: 3, // Add padding here
            minWidth: 300,
        }}>
          <form onSubmit={handleSubmit}>
            <Button variant="contained" type="submit" fullWidth>
              Sign In
            </Button>
          </form>
          <ToastContainer />
        </Box>
        </Paper>
    </React.Fragment>
  )
}
export default Login