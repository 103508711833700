import React, { useEffect, useState, useRef} from "react";
import { addMemberBackend, app, getMembersBackend } from "../Connectors/firebase";
import { doc, query, onSnapshot, getFirestore, setDoc, deleteDoc,collection, getDoc} from "firebase/firestore"
import {OutlinedInput} from "@mui/material";

import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';
import { Box, IconButton } from "@mui/material";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {Dialog} from "@mui/material";
import {DialogTitle} from "@mui/material";
import {DialogActions} from "@mui/material";
import Paper from '@mui/material/Paper';
import SaveIcon from '@mui/icons-material/Save';
import { useTheme } from "@emotion/react";
import Title from '../Utils/Title';
import {MenuItem} from "@mui/material";
import InputLabel from '@mui/material/InputLabel';

import AddCircleIcon from '@mui/icons-material/AddCircle';

import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import FormDialog from "../Utils/PopupDialog";

import MultiLingualContent from '../Utils/MultilingualContent'
import MediaQuery from "react-responsive";
import CloseIcon from '@mui/icons-material/Close';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import { toast, ToastContainer } from "react-toastify";

// Generate Order Data
function createData(id, pid,  date, name, description, credits, linked, oid) {
  return { id, pid,  date, name, description, credits, linked, oid};
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};


  
  function getStyles(name, moduleName, theme) {
    return {
      fontWeight:
      moduleName.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }

const db = getFirestore(app);


export default function CurrentProjects(props)
{
    const theme = useTheme();

    const [projectList, setprojectList] = useState([]);
    const [WantsToAddProject, setWantsToAddProject] =useState(false);
    const [WantsToEditProject, setWantsToEditProject] =useState(null);
    const [WantsToDeleteProject, setWantsToDeleteProject] = useState(false);
    const [ProjectToBeDeletedPermanently, setProjectToBeDeletedPermanently] = useState(null);
    const [dialogOpen, setDialogOpen] = useState(false); // new state for dialog open/close
    const [temporaryCustomerData, setTemporaryCustomerData] = useState({});
    const [selectedModuleName, setSelectedModuleName] = React.useState([]);
    const [selectedMember, setSelectedMember] = React.useState([]);

    const [modules, setModules] = React.useState([]);
    const [availableMembers, setAvailableMembers] = useState([]);

    const newProjectFrom = useRef(null);


    const handleSaveNewProject = () => {
        setDialogOpen(false);
        persistProject();
    };

    const handleCloseProjectDialog = () => {
        setDialogOpen(false);
    };

    
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedModuleName(
      // On autofill we get a stringified value.
      typeof value.name === 'string' ? value.split(',') : value,
    );
    setTemporaryCustomerData({...temporaryCustomerData, customer_modules: typeof value === 'string' ? value.split(',') :  value});
  };

  const handleMemberListChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedMember(
      // On autofill we get a stringified value.
      typeof value.displayName === 'string' ? value.split(',') : value,
    );
    setTemporaryCustomerData({...temporaryCustomerData, members: typeof value === 'string' ? value.split(',') :  value});
  };

  useEffect(() => {
    const topicsCollection = collection(db, "Topics");

    // Create a query if needed (optional)
    // const q = query(topicsCollection, where("someField", "==", "someValue"));

    // Use onSnapshot to listen for updates
    const unsubscribe = onSnapshot(topicsCollection, (snapshot) => {
      const topicsData = snapshot.docs.map((doc) => {
        const topicJSON = JSON.parse(doc.data().topicJSON);
        console.log(topicJSON.name);
        return { id: doc.id, name: topicJSON.name };
      });
      setModules(topicsData);
    });

    // Clean up subscription on component unmount
    return () => {
      unsubscribe();
    };
  }, [props.uid, props.projectSelected]);







    function Projects(rows, wantsToAdd) {
        const currentDate = new Date();
        const confirm_deletion_text = MultiLingualContent({contentID:"confirm_deletion"});
        return (
          <React.Fragment>
            <ToastContainer />
            <Dialog 
                open={dialogOpen}
                fullScreen={false} 
                onClose={handleCloseProjectDialog} 
                scroll={'paper'}
                fullWidth={true}
                >
                <DialogTitle>Add Project</DialogTitle>
                <Paper elevation={0} sx={{ width:"100%"}}>
                    <Box
                        style={{
                        backgroundColor: dialogOpen ? 'rgba(255, 255, 255, 1)' : null,
                        padding: '40px',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '20px'
                        }}
                    ><TextField
                    id="customer_name"
                    label="Nombre del Cliente"
                    value={temporaryCustomerData?.customer_name || ''}
                    placeholder="Introduzca aquí el nombre del cliente"
                    onChange={(e) => setTemporaryCustomerData({ ...temporaryCustomerData, customer_name: e.target.value })}
                    fullWidth
                  />
                  <TextField
                    id="project_description"
                    label="Descripción del Proyecto"
                    value={temporaryCustomerData?.project_description || ''}
                    onChange={(e) => setTemporaryCustomerData({ ...temporaryCustomerData, project_description: e.target.value })}
                    fullWidth
                  />
                  <InputLabel id="demo-multiple-chip-label">Selecciona a tus compañeros</InputLabel>
                  <Select
                        label="Compañeros de proyecto"
                        labelId="demo-multiple-chip-label"
                        id="demo-multiple-chip"
                        multiple
                        value={selectedMember}
                        onChange={handleMemberListChange}
                        input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                        renderValue={(selected) => (
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                            {selected.map((value) => (
                                <Chip key={value} label={value} />
                            ))}
                            </Box>
                        )}
                        MenuProps={MenuProps}
                        >
                        {availableMembers.length>0? availableMembers.map((member) => (
                            <MenuItem
                            key={member.uid}
                            value={member.displayName}
                            style={getStyles(member.displayName, selectedMember, theme)}
                            >
                            {member.displayName}
                            </MenuItem>
                        )):null}
                  </Select>
                  <InputLabel id="demo-multiple-chip-label">Selecciona los Módulos de NS</InputLabel>
                  <Select
                        label="Módulos de NS"
                        labelId="demo-multiple-chip-label"
                        id="demo-multiple-chip"
                        multiple
                        value={selectedModuleName}
                        onChange={handleChange}
                        input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                        renderValue={(selected) => (
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                            {selected.map((value) => (
                                <Chip key={value} label={value} />
                            ))}
                            </Box>
                        )}
                        MenuProps={MenuProps}
                        >
                        {modules.map((ns_module) => (
                            <MenuItem
                            key={ns_module.id}
                            value={ns_module.name}
                            style={getStyles(ns_module.name, selectedModuleName, theme)}
                            >
                            {ns_module.name}
                            </MenuItem>
                        ))}
                  </Select>
                  <TextField
                    id="customer_description"
                    label="Descripción de la Empresa (se usará en ASIS)"
                    value={temporaryCustomerData?.customer_description || ''}
                    onChange={(e) => setTemporaryCustomerData({ ...temporaryCustomerData, customer_description: e.target.value })}
                    multiline
                    rows={4}
                    fullWidth
                  />
                  <TextField
                    id="customer_pm"
                    label="Nombre del Gestor de Proyecto"
                    value={temporaryCustomerData?.customer_pm || ''}
                    onChange={(e) => setTemporaryCustomerData({ ...temporaryCustomerData, customer_pm: e.target.value })}
                    fullWidth
                  />
                  <TextField
                    id="customer_sponsor"
                    label="Nombre del Sponsor"
                    value={temporaryCustomerData?.customer_sponsor || ''}
                    onChange={(e) => setTemporaryCustomerData({ ...temporaryCustomerData, customer_sponsor: e.target.value })}
                    fullWidth
                  />
                  
                </Box>
              </Paper>
              <DialogActions>
                <IconButton onClick={handleSaveNewProject} color="primary">
                  <SaveIcon />
                </IconButton>
                <IconButton onClick={handleCloseProjectDialog} color="primary">
                  <CloseIcon />
                </IconButton>
              </DialogActions>
            </Dialog>




            <Title><MultiLingualContent contentID="project"/></Title>
            <MediaQuery minWidth={600}>
            {!!WantsToDeleteProject?(<FormDialog label={<MultiLingualContent contentID="delete_project"/>} text={confirm_deletion_text+WantsToDeleteProject} cbCancel={()=>{setWantsToDeleteProject(null)}} cbOk={()=>permanentlyDeleteProject(WantsToDeleteProject)} cbChange={setProjectToBeDeletedPermanently} txtCancel={<MultiLingualContent contentID="cancel"/>} txtOk={<MultiLingualContent contentID="delete_project"/>}/>):(null)}
            <form ref={newProjectFrom}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell><MultiLingualContent contentID="created"/></TableCell>
                  <TableCell><MultiLingualContent contentID="name"/></TableCell>
                  <TableCell><MultiLingualContent contentID="short_description"/></TableCell>
                  <TableCell align="right"><MultiLingualContent contentID="credits_used"/></TableCell>                  
                  <TableCell align="center"><MultiLingualContent contentID="actions"/></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {projectList?(rows.map((row) => (
                    
                    <TableRow key={row.id}>
                        <TableCell>{row.date}</TableCell>
                        <TableCell>{row.name}</TableCell>
                        <TableCell>{row.description}</TableCell>
                        <TableCell align="right">{row.credits}</TableCell>
                        <TableCell>
                          {!row.linked?(<>
                            {/*<IconButton color="primary" aria-label={<MultiLingualContent contentID="edit"/>} component="span" onClick={()=>editProject(row.id)}>
                                <EditIcon />
                            </IconButton>*/}
                            <IconButton style={{ color: 'red' }} aria-label={<MultiLingualContent contentID="remove"/>} component="span" onClick={()=>deleteProject(row.id)}>
                                <DeleteForeverIcon />
                            </IconButton></>):(null)
                            }
                        </TableCell>
                    </TableRow>
                ))):(null)}
                
              </TableBody>
            </Table>
            </form>
            <Link color="primary" href="#" onClick={addProject} sx={{ mt: 3 }}>
                <Box sx={{
                    display:"flex",
                    flexDirection: "row",
                    justifyContent: "center"
                }}>
                    <AddCircleIcon /><div style={{marginLeft:7}}><MultiLingualContent contentID="add_new_project"/></div>
                </Box>
            </Link>
            </MediaQuery>
            {/*Phone and tablets */}
            <MediaQuery maxWidth={600}>
            {!!WantsToDeleteProject?(<FormDialog label={<MultiLingualContent contentID="delete_project"/>} text={confirm_deletion_text+WantsToDeleteProject} cbCancel={()=>{setWantsToDeleteProject(null)}} cbOk={()=>permanentlyDeleteProject(WantsToDeleteProject)} cbChange={setProjectToBeDeletedPermanently} txtCancel={<MultiLingualContent contentID="cancel"/>} txtOk={<MultiLingualContent contentID="delete_project"/>}/>):(null)}
            <form ref={newProjectFrom}>
            <Table size="small">
            <TableHead>
                <TableRow>
                  <TableCell><MultiLingualContent contentID="name"/></TableCell>
                  <TableCell align="right"><MultiLingualContent contentID="credits_used"/></TableCell>                  
                  <TableCell align="center"><MultiLingualContent contentID="actions"/></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {projectList?(rows.map((row) => (
                    <TableRow key={row.id}>
                        <TableCell>{row.name}{' - '}{row.description}{'\n'}{row.date}</TableCell>
                        <TableCell align="right">{row.credits}</TableCell>
                        <TableCell>
                            {!row.linked?(<>
                            {/*<IconButton color="primary" aria-label={<MultiLingualContent contentID="edit"/>} component="span" onClick={()=>editProject(row.id)}>
                                <EditIcon />
                            </IconButton>*/}
                            <IconButton style={{ color: 'red' }} aria-label={<MultiLingualContent contentID="remove"/>} component="span" onClick={()=>deleteProject(row.id)}>
                                <DeleteForeverIcon />
                            </IconButton></>):(null)
                            }
                        </TableCell>
                    </TableRow>
                ))):(null)}
              </TableBody>
            </Table>
            </form>
            
            <Link color="primary" href="#" onClick={addProject} sx={{ mt: 3 }}>
                <Box sx={{
                    display:"flex",
                    flexDirection: "row",
                    justifyContent: "center"
                }}>
                    <AddCircleIcon /><div style={{marginLeft:7}}><MultiLingualContent contentID="add_new_project"/></div>
                </Box>
            </Link>
            </MediaQuery>
          </React.Fragment>
        );
      }

    function editProject(id) {
        setWantsToEditProject(id);
    }

    function saveEditProject(event)
    {
        event.preventDefault();
        const form = newProjectFrom.current;

        //Looks for repeated project name
        for(var i=0; i<projectList.length; i++)
        {
            if(projectList[i].name === form['name'].value  && projectList[i].id !== form['id'].value)
            {
                //const project_exists = MultiLingualContent({contentID:"project_text"});
                //const already_exists = MultiLingualContent({contentID:"already_exists"});

                toast.error("El proyecto " + form['name'].value + " ya existe",{
                  position: toast.POSITION.TOP_RIGHT
                });
                return(null);
            }
        }


        setDoc(doc(db,"users", props.uid, "projects",form['id'].value),{
            date: form['date'].value,
            name: form['name'].value,
            description: form['desc'].value,
            credits:0
        }).then(()=>{
            setWantsToEditProject(false);
            console.log("Success");
        }).catch((e)=>{
            setWantsToEditProject(false);
            console.log(e);
            //const project_creation_error_alert= MultiLingualContent({contentID:"project_creation_error_alert"});
            toast.error("No se pudo crear el proyecto.",{
              position: toast.POSITION.TOP_RIGHT
            });
        });
    }

    function deleteProject(id) {
        setWantsToDeleteProject(id);
    }

    function permanentlyDeleteProject(id){
        if(ProjectToBeDeletedPermanently === id.toString() )
        {
          const docPath = doc(db, "users", props.uid, "projects", projectList[id-1].pid);
          console.log("Deleting doc at path:", docPath);
            

            deleteDoc(docPath).then(()=>{
                toast.success("Project deleted successfully",{
                    position: toast.POSITION.TOP_RIGHT
                });
                setProjectToBeDeletedPermanently(null);
                setWantsToDeleteProject(null);
            }).catch((e)=>{
                setProjectToBeDeletedPermanently(null);
                setWantsToAddProject(true);
                console.log(e);
                //const project_creation_error_alert= MultiLingualContent({contentID:"project_creation_error_alert"});

                toast.error("No se pudo borrar el proyecto",{
                  position: toast.POSITION.TOP_RIGHT
                });
            });
           
        }else
        {
            //const incorrect_number_alert= MultiLingualContent({contentID:"incorrect_number_alert"});

            toast.error("El número introducido es incorrecto",{
              position: toast.POSITION.TOP_RIGHT
            });
            setWantsToDeleteProject(null);
        }
    }

    function addProject(event) {
        event.preventDefault();
        setWantsToAddProject(true);
        setDialogOpen(true);
    }
    function cancelProject(event) {
        event.preventDefault();
        setWantsToAddProject(false);
    }

    function persistProject() {
        const form = newProjectFrom.current;

        //Looks for repeated project name
        if(projectList)
        {
            for(var i=0; i<projectList.length; i++)
            {
                if(projectList[i].name === form['name'].value)
                {
                     //const project_exists = MultiLingualContent({contentID:"project_text"});
                     //const already_exists = MultiLingualContent({contentID:"already_exists"});

                     toast.error("El proyecto " + form['name'].value + " ya existe.",{
                        position: toast.POSITION.TOP_RIGHT
                      });
                    return(null);
                }
            }
        }

        const timestamp = Date.now();
        const datetime = new Date();
        //Get modules id
        let modules_id=[];
        if(temporaryCustomerData.customer_modules?.length>0) {
          for(var i=0; i<modules.length; i++)
          {
              for(var j=0; j<temporaryCustomerData.customer_modules.length; j++)
              {
                  if(modules[i].name === temporaryCustomerData.customer_modules[j])
                  {
                      modules_id.push(modules[i].id);
                  }
              }
          }
        }
        const projectId = timestamp.toString();

        setDoc(doc(db,"users", props.uid, "projects",projectId),{
            date: datetime.toLocaleString(),
            name: temporaryCustomerData.customer_name,
            description: temporaryCustomerData.project_description,
            customer_description: temporaryCustomerData.customer_description,
            customer_pm: temporaryCustomerData.customer_pm,
            sponsor: temporaryCustomerData.customer_sponsor,
            modules: modules_id.length>0?modules_id:'',
            members: temporaryCustomerData.members?.length>0?temporaryCustomerData.members:'',            
            credits:0


        }).then(async ()=>{ 
            //Get members uid from availableMembers crosschecking with temporaryCustomerData.members
            let members_uid=[];
            if(temporaryCustomerData.members?.length>0) {
              for(var i=0; i<availableMembers.length; i++)
              {
                  for(var j=0; j<temporaryCustomerData.members.length; j++)
                  {
                      if(availableMembers[i].displayName === temporaryCustomerData.members[j])
                      {
                          members_uid.push(availableMembers[i].uid);
                      }
                  }
              }

              await addMemberBackend({pid: projectId, members_uid_array: members_uid})
            }
            
            setWantsToAddProject(false);

        }).catch((e)=>{
            setWantsToAddProject(true);
            console.log(e);
            //const project_creation_error_alert = MultiLingualContent({contentID:"project_creation_error_alert"});

            toast.error("Error al crear el proyecto",{
              position: toast.POSITION.TOP_RIGHT
            });
        });
    }
    useEffect(() => {
        const userProjects = query(collection(db, '/users/'+props.uid+'/projects'));
        const unsuscribe = onSnapshot(userProjects, async (projectsList) => {  
            if (projectsList.size) {
                let list=[];
                let count=0;
                for(const projectDoc of projectsList.docs) {
                    count=count+1;
                    if(projectDoc.data()?.uid!==undefined)
                    {
                      
                      const linkedProjectDoc = await getDoc(doc(db,'/users/'+projectDoc.data().uid+'/projects/'+projectDoc.data().pid));
                      if (!linkedProjectDoc.exists) {
                          throw new Error('Error al recuperar el proyecto');
                      }else{
                        list.push(createData(count, linkedProjectDoc.id, linkedProjectDoc.data().date, linkedProjectDoc.data().name, linkedProjectDoc.data().description, linkedProjectDoc.data().credits, true, linkedProjectDoc.data().uid));
                      }
                      
                    }else{
                      list.push(createData(count, projectDoc.id, projectDoc.data().date, projectDoc.data().name, projectDoc.data().description, projectDoc.data().credits, false, props.uid ));
                    }
                }
                //console.log(list);
                setprojectList(list);
            }else{
                setprojectList(null);
                console.log("Could not get docs... Retry, please.");
            }
        });
        return () => {
            unsuscribe();
        }
    }, []);
    
 
    async function getMembersLocal() {
      // Supongamos que esta función hace uso de Firestore
     return await getMembersBackend();
    }

    useEffect(() => {
      getMembersLocal().then((result) => {
        if(result.data.success) 
        {
          setAvailableMembers(result.data.users);
        }
      });
    }, []);


    return(
    <>{WantsToAddProject? Projects(projectList, true):Projects(projectList, false)}</>)
} 
