import React from 'react';
import { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import { styled, ThemeProvider, createTheme, useTheme } from '@mui/material/styles';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import TextField from '@mui/material/TextField';
import TopicIcon from '@mui/icons-material/Topic';
import HelpCenterIcon from '@mui/icons-material/HelpCenter';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import Input from '@mui/material/Input';
import DialogActions from '@mui/material/DialogActions';
import SaveIcon from '@mui/icons-material/Save';
import AddIcon from '@mui/icons-material/Add';
import { Delete } from '@material-ui/icons';
import EditIcon from '@mui/icons-material/Edit';
import useMediaQuery from '@mui/material/useMediaQuery';
import { addTopic } from '../Connectors/firebase';



const FireNav = styled(List)({
    '& .MuiListItemButton-root': {
      paddingLeft: 24,
      paddingRight: 24,
    },
    '& .MuiListItemIcon-root': {
      minWidth: 0,
      marginRight: 16,
    },
    '& .MuiSvgIcon-root': {
      fontSize: 20,
    },
  });




export default function TopicPaper({  topic, 
    index, 
    handleChangeTopicName, 
    addSubtopic, 
    handleChange,
    handleChangeTitle,
    handleDeleteQuestion,
    setSelectedSubtopicIndex,
    addQuestion }) {

    const [open, setOpen] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false); // new state for dialog open/close
    const [dialogTitle, setDialogTitle] = useState(''); // new state for dialog title
    const [dialogQuestions, setDialogQuestions] = useState([]); // new state for dialog title
    const [dialogTitleEditable, setDialogTitleEditable] = useState([]);
    const [topicNameEditable, setTopicNameEditable] = useState(false);
    const [localSelectedSubtopicIndex, setLocalSelectedSubtopicIndex] = useState(0);

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));


    const handleKeyUp = (e, callback) => {
        if (e.key === 'Enter') {
          callback();
        }
      }

    const handleEditTopicLabel = () => {
        setTopicNameEditable(!topicNameEditable);
      }

    const handleSubtopicClick = (label, Questions, subtopicIndex) => () => {
        setSelectedSubtopicIndex(subtopicIndex);
        setLocalSelectedSubtopicIndex(subtopicIndex);
        setDialogTitle(label);
        setDialogQuestions(Questions);
        setDialogOpen(true);
      };


    const handleSave = async () => {
        const topicId = await addTopic({topicJSON: JSON.stringify(topic)});
        console.log("Updated/Added topic with Id:", topicId);
    }

    const handleOpenClose = () => {
        setOpen(!open);
    };

    const handleEditSubtopicLabel = () => {
        setDialogTitleEditable(!dialogTitleEditable);
      }

    // function to close dialog
    const handleClose = () => {
        setDialogOpen(false);
    };

    return (
    <Paper elevation={0} sx={{ width:"100%", mb:1}}>
    <Dialog 
    open={dialogOpen}
    fullScreen={fullScreen} 
    onClose={handleClose} 
    scroll={'paper'}
    fullWidth={true}
    >
    <DialogTitle>{dialogTitleEditable ? 
    <div>
    <TextField value={dialogTitle} onChange={(e)=>{
        setDialogTitle(e.target.value);
        handleChangeTitle(index,e)}
    }/>
    </div> 
    :
    <div>
    <IconButton onClick={(e)=>handleEditSubtopicLabel()} color="primary">
    <EditIcon/>
    </IconButton> 
    {dialogTitle} 
    </div>
    }
    </DialogTitle>
        

    <ThemeProvider
    theme={createTheme({
    components: {
    MuiListItemButton: {
        defaultProps: {
        disableTouchRipple: true,
        },
    },
    },
    palette: {
    mode: 'dark',
    primary: { main: 'rgb(102, 157, 246)' },
    background: { paper: '#434867' },
    },
    })}
    >
    <Paper elevation={0} sx={{ width:"100%"}}>

    <FireNav component="nav" disablePadding>

        
        <Box
        sx={{
            bgcolor: open ? 'rgba(255, 255, 255, 1)' : null,
            p:5,

        }}
        >
        {
    dialogQuestions.map((item, questionIndex) => (
        
        <ListItemButton
            key={questionIndex}
            sx={{ 
                py: 0, 
                minHeight: 32, 
                color: 'rgba(255,255,255,1)', 
                backgroundColor: '#434867 !important',
                borderRight: '1px solid rgba(255,255,255,1)', // Add right border to each item
                borderLeft: '1px solid rgba(255,255,255,1)', // Add left border to each item
                borderBottom: '2px solid rgba(255,255,255,1)', // Add bottom border to each item
                ...(questionIndex === 0 && { borderTop: '2px solid rgba(255,255,255,1)' }) 
            }}
            alignItems="center"  // Align items in the center vertically
        >
            <ListItemIcon sx={{ color: 'inherit' }} >
                <HelpCenterIcon/>
            </ListItemIcon>
            <Box flexGrow={1}>
                <Input 
                    fullWidth
                    value={item.question}
                    onChange={(e)=>{
                        const tempDialogQuestions = [...dialogQuestions];
                        tempDialogQuestions[questionIndex].question = e.target.value;
                        setDialogQuestions(tempDialogQuestions);
                        handleChange(e,index, questionIndex)}}
                    sx={{ 
                        fontSize: 14, 
                        fontWeight: 'medium', 
                        color: 'rgba(255,255,255,0.8)',
                        '&:hover': {color: 'rgba(255,255,255,1)'},
                    }}
                    onClick={e => {
                        e.target.select()
                    }}
                    disableUnderline={true}
                />
            </Box>                    
            <IconButton onClick={handleDeleteQuestion(index, questionIndex)} color="primary" >
                <Delete/>
            </IconButton>
        </ListItemButton>
    
    ))}

    <ListItemButton
        key={9999999}
        width="100%"
        onClick={(e)=>{
                console.log("Adding a new question... at index ", index);
                const tempTopic = addQuestion(index)
                setDialogQuestions(tempTopic[index].Subtopics[localSelectedSubtopicIndex].Questions);                
            }
        }
        sx={{ 
            pt: 1, 
            minHeight: 32, 
            color: 'rgba(0,0,0,0.8)', 
            backgroundColor: '#fff !important',
            borderRight: '1px solid rgba(255,255,255,1)', // Add right border to each item
            borderLeft: '1px solid rgba(255,255,255,1)', // Add left border to each item
            borderBottom: '2px solid rgba(255,255,255,1)', // Add bottom border to each item
        }}
        >
        <ListItemIcon sx={{ color: 'inherit', '&:hover':{color: 'rgba(0,0,0,1)'}}}>
            <AddIcon/>
        </ListItemIcon>
        <ListItemText
            primary="Add a question"
            primaryTypographyProps={{
            fontSize: 15,
            fontWeight: 'medium',
            lineHeight: '20px',
            mb: '2px',
            }}
            sx={{ 
            fontSize: 14, 
            fontWeight: 'medium', 
            color: 'rgba(0,0,0,0.8)',
            '&:hover': {color: 'rgba(0,0,0,1)'},
        }}/>
    </ListItemButton>

    </Box>
    </FireNav>
    </Paper>
    </ThemeProvider>
    <DialogActions>
    <IconButton onClick={handleClose} color="primary">
    <SaveIcon/>
    </IconButton>
    </DialogActions>
    </Dialog>
    <FireNav component="nav" disablePadding>
    <Box
    sx={{
    bgcolor: open ? 'rgba(71, 98, 130, 0.2)' : null,
    pb: open ? 2 : 0,
    pr:0,
    pl:0,
    }}
    >
    <ListItemButton
    alignItems="flex-start"
    onClick={handleOpenClose}
    sx={{
        px: 3,
        pt: 2.5,
        pb: open ? 0 : 2.5,
        '&:hover, &:focus': { '& svg': { opacity: open ? 1 : 0 } },
        '&:hover': {
        backgroundColor: 'rgba(255,255,255,.0)'
        }
    }}
    >
    <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
    {topicNameEditable ? 
        (<TextField 
            value={topic.name} 
            onChange={(e)=>handleChangeTopicName(index,e)} 
            onKeyUp={(e)=>handleKeyUp(e, handleEditTopicLabel)}
            onBlur={handleEditTopicLabel}
            sx={{
                my: 0,
                fontSize: 15,
                fontWeight: 'medium',
                lineHeight: '20px',
                mb: '2px',
            }}/>
        )
        :
        (<Box display="flex" alignItems="center">

            {!open ? (<ListItemText
            primary={topic.name}
            primaryTypographyProps={{
                fontSize: 15,
                fontWeight: 'medium',
                lineHeight: '20px',
                mb: '2px',
            }}
            secondary={
                ((s) => s.length > 60 ? s.substring(0, 60).concat("...") : s)(topic.Subtopics.map(item => item.label).join(", "))
            }
            secondaryTypographyProps={{
                noWrap: true,
                fontSize: 12,
                lineHeight: '16px',
                color: open ? 'rgba(0,0,0,0)' : 'rgba(255,255,255,0.5)',
            }}
            sx={{ my: 0 }}
            />)
            :(<><ListItemText
            primary={topic.name}
            primaryTypographyProps={{
                fontSize: 15,
                fontWeight: 'medium',
                lineHeight: '20px',
                mb: '2px',
            }}
            sx={{ my: 0, mr: 1 }}
            />
            
                                
            <IconButton onClick={handleEditTopicLabel} color="primary">
            <EditIcon/>
        </IconButton>
            <IconButton onClick={handleSave} color="primary">
            <SaveIcon/>
            </IconButton></>
        )}
        </Box>)
    }

    <Box sx={{backgroundColor: '#00875A', borderRadius:8, paddingInline:1, fontSize:10, lineHeight: '20px'}}>{topic.Subtopics.length}</Box>
    </Box>

    <KeyboardArrowDown
        sx={{
        mr: -1,
        opacity: 0,
        transform: open ? 'rotate(-180deg)' : 'rotate(0)',
        transition: '0.2s',
        }}
    />
    </ListItemButton>
    {open &&
    topic.Subtopics.map((item, indexSubtopics) => (
        <Box sx={{mx:2}}>
        <ListItemButton
        key={item.label}
        onClick={handleSubtopicClick(item.label, item.Questions, indexSubtopics)}
        sx={{ 
        py: 0, 
        minHeight: 32, 
        color: 'rgba(255,255,255,1)', 
        backgroundColor: 'rgba(255,255,255,.2)', 
        borderRight: '1px solid rgba(255,255,255,0.5)', // Add right border to each item
        borderLeft: '1px solid rgba(255,255,255,0.5)', // Add left border to each item
        borderBottom: '1px solid rgba(255,255,255,0.5)', // Add bottom border to each item
        ...(indexSubtopics === 0 && { borderTop: '1px solid rgba(255,255,255,0.5)' }) // If it's the first item, add top border
        }}
    >
        <ListItemIcon sx={{ color: 'inherit' }}>
        <TopicIcon />
        </ListItemIcon>
        <ListItemText
        primary={item.label}
        primaryTypographyProps={{ fontSize: 14, fontWeight: 'medium' }}
        />
        <Box sx={{backgroundColor: 'rgba(255,255,255,0.5)', borderRadius:8, paddingInline:1, fontSize:10}}>{item.Questions.length}</Box>

    </ListItemButton>
    
    
    </Box>))}

    <ListItemButton
        onClick={(e)=>{addSubtopic(index)}}
        sx={{
        px: 3,
        pt: open ? 2.5 : 0,
        pb: open ? 0 : 2.5,
        '&:hover, &:focus': { '& svg': { opacity: open ? 1 : 0 } },
        '&:hover': {
            backgroundColor: 'rgba(255,255,255,.0)'
        }
        }}
    >
        <ListItemIcon sx={{ color: 'inherit' }}>
        <AddIcon />
        </ListItemIcon>
        <ListItemText
        primary={"Add a subtopic"}
        primaryTypographyProps={{ fontSize: 14, fontWeight: 'medium' }}
        /> 
    </ListItemButton>
    </Box>
    </FireNav>
    </Paper>
  );
}