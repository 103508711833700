import { BrowserRouter as Router, Routes, Route } from "react-router-dom"

import React from "react";
import Signup from "./components/signup"

import {Runtime} from "./components/Runtime"

import {AuthProvider} from './components/Connectors/context'
import { LanguageContext } from "./components/Utils/multilingualCtx"
import {useState, useEffect} from "react"
import detectBrowserLanguage from 'detect-browser-language';

function App(){
  const [language, setLanguage] = useState("english");

  useEffect(() => {
    let language = detectBrowserLanguage();    
    setLanguage((language)=> (language.split("-")==="en"? "english": "spanish"));
  }, []);


  function toggleLanguage() {
    setLanguage((language) => (language === "english" ? "spanish" : "english"));
  }

  return (
    <AuthProvider>
      <LanguageContext.Provider value={{ language, toggleLanguage }}>
        <Router>
          <Routes>
            <Route path="/" element={<Signup/>}/>
            <Route path="/signup" element={<Signup/>}/>
            <Route path="/app" element={<Runtime section="Dashboard"/>}/>
            <Route path="/p/:pid/meeting/:id" element={<Runtime section="Meeting"/>}/>
          </Routes>
        </Router>
      </LanguageContext.Provider>
    </AuthProvider>
    );
};

export default App;