import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { TextField, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Select, MenuItem } from '@mui/material';
import { collection, query, where, onSnapshot, getDocs, getDoc, doc } from 'firebase/firestore';
import { db, joinCall,joinCallDebug, endCall, endCallDebug } from '../Connectors/firebase';
import  TopicStatus from '../Utils/TopicStatus';

export default function NewScope(props) {
  const navigate = useNavigate();
  const [joinURL, setJoinURL] = useState('');
  const [connected, setConnected] = useState(false);
  const [meetings, setMeetings] = useState([]);
  const [topics, setTopics] = useState([]);
  const [selectedTopic, setSelectedTopic] = useState({});

  const handleJoin = async () => {
    const result = await joinCall({ JoinURL: joinURL, pid: props.projectSelected.pid, oid: props.projectSelected.oid, mainTopicId: selectedTopic.id, date: new Date().toString()});
    if (result.data.success) {
      setConnected(true);
    }
  };

  const handleJoinDebug = async () => {
    const result = await joinCallDebug({ JoinURL: joinURL, pid: props.projectSelected.pid, oid: props.projectSelected.oid, mainTopicId: selectedTopic.id, date: new Date().toString()});
    if (result.data.success) {
      setConnected(true);
    }
  };

  const handleEnd = async (meetingId) => {
    const result = await endCall({ callId: meetingId, pid: props.projectSelected.pid, oid: props.projectSelected.oid });
    if (result.data.success) {
      setConnected(false);
    }
  };

  const handleEndDebug = async (meetingId) => {
    const result = await endCallDebug({ callId: meetingId, pid: props.projectSelected.pid, oid: props.projectSelected.oid });
    if (result.data.success) {
      setConnected(false);
    }
  };

  const handleTopicChange = (event) => {
    setSelectedTopic(topics.find(topic => topic.id === event.target.value));
  };

  const navigateToMeetingDashboard = (meetingId) => {
    console.log("Changing scope...")
    const args = null;
    props.sectionSelectorCB("Meeting", args)
    navigate(`/p/${props.projectSelected.pid}/meeting/${meetingId}`);
  }


  useEffect(() => {
    let unsubscribe;

    //Check if project is linked
    if (props.projectSelected.linked) {
      
      const q = query(collection(db, 'users', props.projectSelected.oid, 'projects', props.projectSelected.pid, 'meetings'), where('onGoing', '==', true));
      unsubscribe = onSnapshot(q, (querySnapshot) => {
        const meetingsData = [];
        const promises = querySnapshot.docs.map(async meeting => {
          try {
            const topicDoc = await getDoc(doc(db, 'users', props.projectSelected.oid, 'projects', props.projectSelected.pid, 'meetings', meeting.id, 'topic', meeting.data().mainTopicId));
            
            if (topicDoc.exists()) {
              const topicData = topicDoc.data();
              console.log(topicData.topic.name);
              return { id: meeting.id, ...meeting.data(), topicName: topicData.topic.name };
            } else {
              console.error("Topic doc does not exist");
            }
          } catch (error) {
            console.error("An error occurred: ", error);
          }
        });
    
        Promise.all(promises)
          .then(meetingsData => {
            setMeetings(meetingsData); // Removes any undefined values
          })
      });



    } else {
  
      const q = query(collection(db, 'users', props.uid, 'projects', props.projectSelected.pid, 'meetings'), where('onGoing', '==', true));
      unsubscribe = onSnapshot(q, (querySnapshot) => {
        const meetingsData = [];
        const promises = querySnapshot.docs.map(async meeting => {
          try {
            const topicDoc = await getDoc(doc(db, 'users', props.uid, 'projects', props.projectSelected.pid, 'meetings', meeting.id, 'topic', meeting.data().mainTopicId));
            
            if (topicDoc.exists()) {
              const topicData = topicDoc.data();
              console.log(topicData.topic.name);
              return { id: meeting.id, ...meeting.data(), topicName: topicData.topic.name };
            } else {
              console.error("Topic doc does not exist");
            }
          } catch (error) {
            console.error("An error occurred: ", error);
          }
        });
    
        Promise.all(promises)
          .then(meetingsData => {
            setMeetings(meetingsData); // Removes any undefined values
          })
      });

   }
   
   const fetchTopics = async () => {
    const topicsCollection = collection(db, 'Topics');
    const topicSnapshot = await getDocs(topicsCollection);
    const topicsData = topicSnapshot.docs.map(doc => {
      const topicJSON = JSON.parse(doc.data().topicJSON);
      return { id: doc.id, topic: topicJSON.name, topicJSON: topicJSON }
    });
    setTopics(topicsData);
    if(topicsData.length > 0){
      setSelectedTopic(topicsData[0]); // Select the first topic by default
    }
  }

   fetchTopics();
    return () => unsubscribe();
  }, [props.uid, props.projectSelected]);


  useEffect(() => {
    let unsubscribe;
    if(props.projectSelected.linked)
    {
      const q = query(collection(db, 'users', props.projectSelected.oid, 'projects', props.projectSelected.pid, 'meetings'), where('onGoing', '==', true));
      
      unsubscribe = onSnapshot(q, querySnapshot => {
        const promises = querySnapshot.docs.map(async meeting => {
          try {
            const topicDoc = await getDoc(doc(db, 'users', props.projectSelected.oid, 'projects', props.projectSelected.pid, 'meetings', meeting.id, 'topic', meeting.data().mainTopicId));
            
            if (topicDoc.exists()) {
              const topicData = topicDoc.data();
              console.log(topicData.topic.name);
              return { id: meeting.id, ...meeting.data(), topicName: topicData.topic.name };
            } else {
              console.error("Topic doc does not exist");
            }
          } catch (error) {
            console.error("An error occurred: ", error);
          }
        });
    
        Promise.all(promises)
          .then(meetingsData => {
            setMeetings(meetingsData); // Removes any undefined values
          })
          .catch(error => console.error("An error occurred when resolving promises: ", error));
      });
    }
    else
    {
      const q = query(collection(db, 'users', props.uid, 'projects', props.projectSelected.pid, 'meetings'), where('onGoing', '==', true));
    
      unsubscribe = onSnapshot(q, querySnapshot => {
        const promises = querySnapshot.docs.map(async meeting => {
          try {
            const topicDoc = await getDoc(doc(db, 'users', props.uid, 'projects', props.projectSelected.pid, 'meetings', meeting.id, 'topic', meeting.data().mainTopicId));
            
            if (topicDoc.exists()) {
              const topicData = topicDoc.data();
              console.log(topicData.topic.name);
              return { id: meeting.id, ...meeting.data(), topicName: topicData.topic.name };
            } else {
              console.error("Topic doc does not exist");
            }
          } catch (error) {
            console.error("An error occurred: ", error);
          }
        });
    
        Promise.all(promises)
          .then(meetingsData => {
            setMeetings(meetingsData); // Removes any undefined values
          })
          .catch(error => console.error("An error occurred when resolving promises: ", error));
      });
    }
  
    return () => unsubscribe();
  
  }, [props.uid, props.projectSelected]);




  return (
    <div>
      <TextField 
        label="Teams Meeting Link" 
        variant="filled"
        color="success"
        focused
        value={joinURL} 
        onChange={e => setJoinURL(e.target.value)} 
      />
      <Select
        value={selectedTopic.id}
        onChange={handleTopicChange}
      >
        {topics.map((topic) => (
          <MenuItem key={topic.id} value={topic.id}>{topic.topic}</MenuItem>
        ))}
      </Select>
      {process.env.NODE_ENV === 'development' && 
        (<Button variant="contained" color="primary" disabled={connected} onClick={handleJoinDebug}>Connect Debug</Button>)
      }
      <Button variant="contained" color="primary" disabled={connected} onClick={handleJoin}>Connect</Button>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Meeting ID</TableCell>
              <TableCell>Topic</TableCell>
              <TableCell align="right">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
          {meetings.map((meeting) => (
              <React.Fragment key={meeting.id}>
                <TableRow>
                  <TableCell component="th" scope="row">
                    {meeting.id}
                  </TableCell>
                  <TableCell component="th" scope="row" align="left">
                    {meeting.topicName}
                  </TableCell>
                  <TableCell align="right">
                    <Button variant="contained" color="secondary" onClick={() => handleEndDebug(meeting.id)}>Disconnect Debug</Button>
                    <Button variant="contained" color="secondary" onClick={() => handleEnd(meeting.id)}>Disconnect</Button>
                    <Button variant="contained" color="secondary" onClick={() => navigateToMeetingDashboard(meeting.id)}>Dashboard</Button>
                  </TableCell>
                </TableRow>
                {/* Check if meeting has valid lastResults and render TopicStatus */}
                {meeting.newestResults && <TableRow>
                  <TableCell colSpan={2}>
                    <TopicStatus status={meeting.newestResults} />
                  </TableCell>
                </TableRow>}
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
