import React from "react";
import Container from '@mui/material/Container';
import firebase from 'firebase/compat/app';
import Toolbar from '@mui/material/Toolbar';

import Box from '@mui/material/Box';

import {Dashboard} from './Dashboard'
import {ASIS} from './ASIS'

import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { Scope } from "./Scope";
import { Meeting } from "./Meeting";
import { Topic } from "./Topic";
import { MeetingNotes } from "./MeetingNotes";

function Copyright(props) {
    return (
      <Typography variant="body2" color="primary.contrastText" align="center" {...props}>
        {'Copyright © '}
        <Link color="primary.contrastText" href="https://ditech.rocketme.ai/">
          RocketMe
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
    );
  }
export class MainContent extends React.Component
{
    constructor(props) {
        super(props);
      }
    

    render() {

        return(
            
                <Box
                component="main"
                sx={{
                    backgroundColor: (theme) =>
                    theme.palette.mode === 'light'
                        ? theme.palette.grey[100]
                        : theme.palette.grey[900],
                    flexGrow: 1,
                    height: '100vh',
                    overflow: 'auto',
                }}
                >
                <Toolbar />
                <Container maxWidth="lg" sx={{ mt: 2, mb: 4 }}>
                    {this.props.section==="Dashboard"?
                        (<Dashboard uid={this.props.uid} projectList={this.props.projectList} projectSelected={this.props.projectSelected} sectionSelectorCB={this.props.sectionSelectorCB}/>):(null)
                    }
                    {this.props.section==="Alcance"?
                        (<Scope uid={this.props.uid} projectList={this.props.projectList} projectSelected={this.props.projectSelected} sectionSelectorCB={this.props.sectionSelectorCB} args={this.props.args}/>):(null)
                    } 
                    
                    {this.props.section==="ASIS"?
                        (<ASIS uid={this.props.uid} projectList={this.props.projectList} projectSelected={this.props.projectSelected} sectionSelectorCB={this.props.sectionSelectorCB} args={this.props.args}/>):(null)
                    } 
                    {this.props.section==="Actas"?
                        (<MeetingNotes uid={this.props.uid} projectList={this.props.projectList} projectSelected={this.props.projectSelected} sectionSelectorCB={this.props.sectionSelectorCB} args={this.props.args}/>):(null)
                    }  
                    
                    {this.props.section==="Meeting"?
                        (<Meeting uid={this.props.uid} projectList={this.props.projectList} projectSelected={this.props.projectSelected} sectionSelectorCB={this.props.sectionSelectorCB} args={this.props.args} projectSelectorFunction ={this.props.projectSelectorFunction}/>):(null)
                    }
                    
                    {this.props.section==="Topic"?
                        (<Topic uid={this.props.uid} projectList={this.props.projectList} projectSelected={this.props.projectSelected} sectionSelectorCB={this.props.sectionSelectorCB} args={this.props.args}/>):(null)
                    }              
                    <Copyright sx={{ pt: 4 }} />
                </Container>
                </Box>          
        )
    };
}