import React from 'react';
import { useRef, useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import { styled, ThemeProvider, createTheme } from '@mui/material/styles';
import List from '@mui/material/List';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import { Typography } from '@mui/material';
import TopicPaper from '../Utils/TopicPaper';

import { db } from '../Connectors/firebase';
import { collection, onSnapshot, query } from 'firebase/firestore'; // don't forget to import these



const FireNav = styled(List)({
  '& .MuiListItemButton-root': {
    paddingLeft: 24,
    paddingRight: 24,
  },
  '& .MuiListItemIcon-root': {
    minWidth: 0,
    marginRight: 16,
  },
  '& .MuiSvgIcon-root': {
    fontSize: 20,
  },
});

export default function NewScope() {
  const [open, setOpen] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false); // new state for dialog open/close
  const [dialogTitle, setDialogTitle] = useState(''); // new state for dialog title
  const [dialogQuestions, setDialogQuestions] = useState([]); // new state for dialog title
  const [inputValues, setInputValues] = useState([]); // new state for dialog title
  const stacks = useRef({}); // we will use a ref to store the undo stack
  // This state is used to keep track of the index of the selected subtopic in the `data` array
  const [selectedSubtopicIndex, setSelectedSubtopicIndex] = useState(null);
  // Set initial data from a constant, so it won't change every render
  const [topics, setTopics] = useState([]);
  const [topicNameEditable, setTopicNameEditable] = useState(false);





  const topicsRef = collection(db, "Topics");


/*
  const addQuestion = (index) => {
    const newData = [...topics];
    newData[index].Subtopics[selectedSubtopicIndex].Questions.push({question: "Your question goes here..."});
    setTopics(newData);
  }

  const addSubtopic = (index) => {
    const newData = [...topics];
    newData[index].subtopics.push({label:"Your subtopic goes here...", questions:["Your question goes here..."]});
    setTopics(newData);
  }


  const handleDeleteQuestion = (index, questionIndex) => () => {
    const newData = [...topics];
    newData[index].subtopics[selectedSubtopicIndex].questions.splice(questionIndex, 1);
    setTopics(newData);
  }

 
  const addNewTopic = () => {
    const newData = [...topics];
    newData.push({ name: 'Your topic goes here...', subtopics: [{label:"Your subtopic goes here...", questions:["Your question goes here..."]}] });
    setTopics(newData);
  }*/

  const addQuestion = (index) => {
    setTopics(prevTopics => prevTopics.map((topic, tIndex) => {
      if (tIndex !== index) return topic;
  
      const newTopic = { ...topic };
      const newSubtopics = [...newTopic.Subtopics];
      newSubtopics[selectedSubtopicIndex].Questions.push({ question: "Your question goes here..." });
  
      return { ...newTopic, Subtopics: newSubtopics };
    }));
    return topics;
  };
  
  const addSubtopic = (index) => {
    setTopics(prevTopics => prevTopics.map((topic, tIndex) => {
      if (tIndex !== index) return topic;
  
      const newSubtopics = [...topic.Subtopics, { label: "Your subtopic goes here...", Questions: [{ question: "Your question goes here..." }] }];
  
      return { ...topic, Subtopics: newSubtopics };
    }));
  };
  
  const handleDeleteQuestion = (index, questionIndex) => () => {
    setTopics(prevTopics => prevTopics.map((topic, tIndex) => {
      if (tIndex !== index) return topic;
  
      const newSubtopics = [...topic.Subtopics];
      newSubtopics[selectedSubtopicIndex].Questions.splice(questionIndex, 1);
  
      return { ...topic, Subtopics: newSubtopics };
    }));
  };
  
  const addNewTopic = () => {
    setTopics(prevTopics => [...prevTopics, { name: 'Your topic goes here...', Subtopics: [{ label: "Your subtopic goes here...", Questions: [{ question: "Your question goes here..." }] }] }]);
  };
  
 



  const handleChange = (e, index, questionIndex) => {
    setTopics(prevTopics => {
      return prevTopics.map((topic, tIndex) => {
        if (tIndex !== index) {
          return topic;
        }
        
        // Clone the current topic
        const newTopic = { ...topic };
  
        // Update the corresponding subtopic and question
        const newSubtopics = [...newTopic.Subtopics];
        const newQuestions = [...newSubtopics[selectedSubtopicIndex].Questions];
        newQuestions[questionIndex] = {
          ...newQuestions[questionIndex],
          question: e.target.value
        };
  
        // Replace the updated arrays
        newSubtopics[selectedSubtopicIndex].Questions = newQuestions;
        newTopic.Subtopics = newSubtopics;
  
        return newTopic;
      });
    });
  }


  const handleChangeTopicName = (index, e) => {
    setTopics(prevTopics => prevTopics.map((topic, tIndex) => {
      if (tIndex !== index) return topic;
  
      return { ...topic, name: e.target.value };
    }));
  };
  
  const handleChangeTitle = (index, e) => {
    setTopics(prevTopics => prevTopics.map((topic, tIndex) => {
      if (tIndex !== index) return topic;
  
      const newSubtopics = [...topic.Subtopics];
      newSubtopics[selectedSubtopicIndex] = { ...newSubtopics[selectedSubtopicIndex], label: e.target.value };
  
      return { ...topic, Subtopics: newSubtopics };
    }));
    
    setDialogTitle(e.target.value);
  };
  
  const handleOpenTopic = (index) => {
    setOpen(prevOpen => {
      const newOpen = [...prevOpen];
      newOpen[index] = !newOpen[index];
      return newOpen;
    });
  };
  
  
  useEffect(() => {
    const unsubscribe = onSnapshot(query(topicsRef), (snapshot) => {
      const fetchedTopics = snapshot.docs.map(doc => {
        try {
          let topicJSONObj = JSON.parse(doc.data().topicJSON);
          topicJSONObj.id = doc.id;

          //Convert the topicJSONObject that contain objects of objects to an array of arrays
          topicJSONObj.Subtopics = Object.entries(topicJSONObj.Subtopics).map(([key, value]) => {
            value.Questions = Object.entries(value.Questions).map(([key, value]) => {
              return {questionId: key, question: value.question, subtopicId: value.subtopicId, topicId: value.topicId};
            });
            return {subtopicId: key, label: value.label, topicId: value.topicId, Questions: value.Questions};
          });


          return topicJSONObj;
        } catch (e) {
          console.error('Invalid JSON in document:', doc.id, 'Error:', e);
          return null;
        }
      }).filter(Boolean); // remove null values
      setTopics(fetchedTopics);
    });

    // Clean up the subscription on unmount
    return () => unsubscribe();
  }, []);

  return (
    <Box>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', p: 1 }}>
        <Box sx={{ flexGrow: 1 }}>
          <h2>Topics</h2>
          <IconButton onClick={addNewTopic} color="primary" sx={{ padding: '0' }}>
            <AddIcon />
            <Typography variant="body1">Add New Topic</Typography>
          </IconButton>
        </Box>
        <Box sx={{ position: 'relative', display: 'inline-flex' }}>
          <TextField id="search-input" label="Search topics" variant="outlined" />
          <IconButton sx={{ position: 'absolute', right: 0, top: '50%', transform: 'translateY(-50%)', color: '#999' }} aria-label="search">
            <SearchIcon />
          </IconButton>
        </Box>
      </Box>

    <Box sx={{ display: 'flex' }}>
      <ThemeProvider
        theme={createTheme({
          components: {
            MuiListItemButton: {
              defaultProps: {
                disableTouchRipple: true,
              },
            },
          },
          palette: {
            mode: 'dark',
            primary: { main: 'rgb(102, 157, 246)' },
            background: { paper: '#434867' },
          },
        })}
      >
        
        {topics.length>0 ?
        <Box sx={{ width: '100%'}}>
        {topics.map((topic, index) => (
       <TopicPaper topic={topic} index={index} handleOpenTopic={handleOpenTopic} open={open} handleChangeTopicName={handleChangeTopicName} setSelectedSubtopicIndex={setSelectedSubtopicIndex} handleChangeTitle={handleChangeTitle} handleChange={handleChange} handleDeleteQuestion={handleDeleteQuestion} addQuestion={addQuestion} addSubtopic={addSubtopic}/>        ))}
        </Box>
        :(null)}
        {topics.length === 0 && 
        //Center text if no topics
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', p: 1 }}>  
        <h3>No topics found</h3>
        </Box>
        }
      </ThemeProvider>
    </Box>
    </Box>
  );
}