import React, {useEffect} from 'react';
import { Card, CardContent, Typography, Grid, Box } from '@mui/material';

import { styled } from '@mui/system';
import PropTypes from 'prop-types';
import LinearProgress from '@mui/material/LinearProgress';

const TitleStyle = styled(Typography)(({ theme }) => ({
  '&.info-card-title': {
    '-webkit-font-smoothing': 'antialiased',
    'text-size-adjust': '100%',
    'box-sizing': 'inherit',
    margin: '0px',
    fontWeight: 400,
    fontSize: '0.875rem',
    lineHeight: 1.57,
    fontFamily: '"Public Sans", sans-serif',
    color: 'rgb(140, 140, 140)',
    }
}));
const ValueStyle = styled(Typography)(({ theme }) => ({
  '&.valueStyleInfoCard': {
  '-webkit-font-smoothing': 'antialiased',
  'text-size-adjust': '100%',
  'box-sizing': 'inherit',
  margin: '0px',
  fontWeight: 600,
  fontSize: '1.25rem',
  lineHeight: 1.4,
  fontFamily: '"Public Sans", sans-serif',
  color: 'inherit',}
}));
function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1}}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <ValueStyle className='valueStyleInfoCard'>{`${Math.round(
          props.value,
        )}%`}</ValueStyle>
      </Box>
    </Box>
  );
}

LinearProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate and buffer variants.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired,
};

export default function ProgressCard({value}) {
  const [progress, setProgress] = React.useState(value);
  // Update the progress state whenever value changes
  useEffect(() => {
    setProgress(value);
  }, [value]);

  return (

    <Card>
      <CardContent>
        <Grid container direction="column">
          <TitleStyle className="info-card-title" variant="h6">Progress</TitleStyle>
          <Grid container justifyContent="space-between">
            <Grid item>           
            <Box sx={{ width: '100%' }}>
              <LinearProgressWithLabel value={progress} />
            </Box>
            </Grid>
          </Grid> 
          
        </Grid>
      </CardContent>
    </Card>
   );
}
