
import React, { useEffect, useState } from "react";
import {app, getFeatures} from "./firebase";
import { getUserPhoto } from "./msalConfig";

export const AuthContext = React.createContext();


export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(undefined);

  const setUserData=async(data)=>{
    if(data)
    {
      const msft_access_token = localStorage.getItem("msft_access_token");

      if(msft_access_token!=null)
      {
        data.msft_access_token = msft_access_token;
      }else{
        data.msft_access_token = null;
      }

      // Fetch the user's profile picture
      const userPhotoUrl = await getUserPhoto(data.msft_access_token);
      data.msft_photoURL = userPhotoUrl;
      setUser(data);
    }
  }

  useEffect(() => {
    const fetchData = async (data) => {
      await setUserData(data);
    };
  
    const unsubscribe = app.auth().onAuthStateChanged((data) => {
      fetchData(data);
    });
  
    // Clean up the listener when the component is unmounted
    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <AuthContext.Provider value={{ user }}>{children}</AuthContext.Provider>
  );
};
