import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import Box from '@mui/material/Box';
import { styled, ThemeProvider, createTheme } from '@mui/material/styles';
import List from '@mui/material/List';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import MediaQuery from 'react-responsive'
import { Typography } from '@mui/material';
import { doc, getDoc, onSnapshot, getDocs, collection} from 'firebase/firestore';

import { db } from '../Connectors/firebase';
import { TopicOutlined } from '@mui/icons-material';
import SubtopicCard from './dashboardComponents/SubtopicCard';

import InfoCard from './dashboardComponents/InfoCard';
import ProgressCard from './dashboardComponents/ProgressCard';

import {addAnswerBackend, editAnswerBackend, delAnswerBackend, flagQuestionAsAnsweredBackend, sintetizeAnswersBackend, delQuestionBackend, addQuestionBackend} from './../Connectors/firebase'


export default function MeetingDashboard(props) {
  const [meeting, setMeeting] = useState(undefined);
  const [subtopics, setSubtopics] = useState(undefined);
  const [questions, setQuestions] = useState(undefined);
  const [answers, setAnswers] = useState(undefined);

  const { pid, id } = useParams();



  //Get initial data for the meeting id
  useEffect(() => {
    // Meeting reference
    if(props.projectSelected.pid===undefined) {
      if(pid!==undefined)
      {
        props.projectSelectorFunction(pid);
      }
      return null;
    }
    const meetingRef = doc(db,'users', props.projectSelected.oid, 'projects', props.projectSelected.pid,'meetings',id);
    getDoc(meetingRef).then(async (meetingDoc) => {
      if (meetingDoc.exists()) {
        const topicRef = doc(db, 'users', props.projectSelected.oid, 'projects', props.projectSelected.pid, 'meetings', id, 'topic', meetingDoc.data().mainTopicId);
        
        const topicDoc = await getDoc(topicRef);
        if (topicDoc.exists()) {
          let meetingTempData = meetingDoc.data();
          meetingTempData.topic = topicDoc.data();
          meetingTempData.topic.ref = topicRef;
          setMeeting(meetingTempData);
        } else {
          console.log("No such topic document!");
        }
      } else {
        console.log("No such meeting document!");
      }
    }).catch((error) => {
      console.log("Error getting document:", error);
    });
  }, [props.uid, props.projectSelected, id]);
  

  //Create a meeting snapshot
  useEffect(() => {
    let unsubscribe;  // Define unsubscribe in outer scope


    // Meeting reference
    if(props.projectSelected.pid===undefined) {
      if(pid!==undefined)
      {
        props.projectSelectorFunction(pid);
      }
      return null;
    }
    const meetingRef = doc(db,'users', props.projectSelected.oid, 'projects', props.projectSelected.pid,'meetings',id);
    getDoc(meetingRef).then(async (meetingDoc) => {
      if (meetingDoc.exists()) {
        const topicRef = doc(db, 'users', props.projectSelected.oid, 'projects', props.projectSelected.pid, 'meetings', id, 'topic', meetingDoc.data().mainTopicId);
        unsubscribe = onSnapshot(topicRef, async (topicDoc) => {
          if (topicDoc.exists()) {
            let meetingTempData = meetingDoc.data();
            meetingTempData.topic = topicDoc.data();
            meetingTempData.topic.ref = topicRef;
            setMeeting(meetingTempData);
          } else {
            console.log("No such topic document!");
          }
        });
      } else {
        console.log("No such meeting document!");
      }
    }).catch((error) => {
      console.log("Error getting document:", error);
    });

    return () => {
      if(unsubscribe) unsubscribe();
    }
  }, [props.uid, props.projectSelected, id]);


 
//Get subtopics for the topic id in the meeting id
  useEffect(() => {
    //Topic reference
      if (meeting && meeting.topic && meeting.topic.ref) {
      const topicRef = meeting.topic.ref;
      getDoc(topicRef).then((topicDoc) => {
        if (topicDoc.exists()) {
          //Get Subtopics
          const subtopicsRef = collection(topicRef, 'Subtopics');
          getDocs(subtopicsRef).then((subtopicsSnapshot) => {
            let subtopicsTemp = [];
            subtopicsSnapshot.forEach((doc) => {
              //Get Questions and Answers for each subtopic
              subtopicsTemp.push({ id: doc.id, ...doc.data(), ref: doc.ref });
            });
            setSubtopics(subtopicsTemp);
          });          
        } else {
          console.log("No such document!");
        }
      }).catch((error) => {
        console.log("Error getting document:", error);
      });
    }
  }, [props.uid, props.projectSelected, id, meeting]);
  



  
  const delAnswer= async (answerId, subtopicId, questionId) =>
  {
    return await delAnswerBackend({
      oid: props.projectSelected.oid,
      pid: props.projectSelected.pid,
      meeting_id: id,
      topicid: meeting.mainTopicId,
      subtopicId: subtopicId,
      questionId: questionId,
      answerId: answerId
    })
  }	

  const addAnswer = async (answer, subtopicId, questionId) =>
  {
    //Update Firestore meeting document
    return await addAnswerBackend({
      oid: props.projectSelected.oid,
      pid: props.projectSelected.pid,
      meeting_id: id,
      topicid: meeting.mainTopicId,
      subtopicId: subtopicId,
      questionId: questionId,
      answer: answer
    })
  }

  const editAnswer= async (answer, answerId, subtopicId, questionId)=>
  {
    return await editAnswerBackend({
      oid: props.projectSelected.oid,
      pid: props.projectSelected.pid,
      meeting_id: id,
      topicid: meeting.mainTopicId,
      subtopicId: subtopicId,
      questionId: questionId,
      answerId: answerId,
      answer: answer
    })
  }

  const answserQuestion=async (questionId, subtopicId)=>
  {
    return await flagQuestionAsAnsweredBackend({
      oid: props.projectSelected.oid,
      pid: props.projectSelected.pid,
      meeting_id: id,
      topicid: meeting.mainTopicId,
      subtopicId: subtopicId,
      questionId: questionId
    });
  }

  const sintetizeAnswers = async (questionId, subtopicId)=>
  {
    return await sintetizeAnswersBackend({
      oid: props.projectSelected.oid,
      pid: props.projectSelected.pid,
      meeting_id: id,
      topicid: meeting.mainTopicId,
      subtopicId: subtopicId,
      questionId: questionId
    });
  }

  const deleteQuestion= async (questionId, subtopicId)=>
  {
    return await delQuestionBackend({      
      oid: props.projectSelected.oid,
      pid: props.projectSelected.pid,
      meeting_id: id,
      topicid: meeting.mainTopicId,
      subtopicId: subtopicId,
      questionId: questionId,
    });
  } 

  const addQuestion = async (questionString, subtopicId) =>
  {
    return await addQuestionBackend({
      oid: props.projectSelected.oid,
      pid: props.projectSelected.pid,
      meeting_id: id,
      topicid: meeting.mainTopicId,
      subtopicId: subtopicId,
      question: questionString
    });
  }

  return (
    <Box>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', p: 1 }}>
        <Box sx={{ flexGrow: 1 }}>
          <h2>
              { meeting ? 
                (meeting.topic ? meeting.topic.topic.name : "") : (null)
              }
              </h2>          
            <Typography variant="body1">{meeting && meeting.date ? meeting.date :""}</Typography>
        </Box>
      </Box>

        
        <Box sx={{ width: '100%'}}>
          <Container>
            {/* Big Screens */}
            <MediaQuery minWidth={850}>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                    <InfoCard 
                      title="Total Preguntas"
                      value={meeting && meeting.topic ? meeting.topic.totalQuestions : ""}
                      percentage={59.3}
                      extra="35,000"
                      icon={<SearchIcon />} 
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                    <InfoCard 
                      title="Preguntas Respondidas"
                      value={meeting && meeting.topic ? meeting.topic.answeredQuestions : ""}
                      percentage={59.3}
                      extra="35,000"
                      icon={<SearchIcon />} 
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>

                    <InfoCard 
                      title="Preguntas no Respondidas"
                      value={meeting && meeting.topic ? meeting.topic.totalQuestions-meeting.topic.answeredQuestions : ""}
                      percentage={59.3}
                      extra="35,000"
                      icon={<SearchIcon />} 
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                <ProgressCard 
                value={
                  (meeting && meeting.topic && meeting.topic.totalQuestions !== 0) 
                  ? (meeting.topic.answeredQuestions / meeting.topic.totalQuestions) * 100 
                  : ""
                } />
                </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      { subtopics? (
                        subtopics.map((subtopic, index) => (
                          <SubtopicCard subtopicData={subtopic} delAnswer={delAnswer} delQuestion={deleteQuestion} addQuestion ={addQuestion} editAnswer={editAnswer} sintetizeAnswers={sintetizeAnswers} answserQuestion={answserQuestion} addAnswer={addAnswer} />
                      ))
                      ):(null)}
                    </Grid>                  
              
                </Grid>
            </MediaQuery>

            {/* Small Screens */}
            <MediaQuery maxWidth={850}>
                <Grid container spacing={2}>

                <Grid item xs={12} sm={6} md={4} lg={3}>
                    <InfoCard 
                      title="Total Preguntas"
                      value={meeting && meeting.topic ? meeting.topic.totalQuestions : ""}
                      percentage={59.3}
                      extra="35,000"
                      icon={<SearchIcon />} 
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                    <InfoCard 
                      title="Preguntas Respondidas"
                      value={meeting && meeting.topic ? meeting.topic.answeredQuestions : ""}
                      percentage={59.3}
                      extra="35,000"
                      icon={<SearchIcon />} 
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>

                    <InfoCard 
                      title="Preguntas no Respondidas"
                      value={meeting && meeting.topic ? meeting.topic.totalQuestions-meeting.topic.answeredQuestions : ""}
                      percentage={59.3}
                      extra="35,000"
                      icon={<SearchIcon />} 
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <ProgressCard 
                  value={
                    (meeting && meeting.topic && meeting.topic.totalQuestions !== 0) 
                    ? (meeting.topic.answeredQuestions / meeting.topic.totalQuestions) * 100 
                    : ""
                  } />
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12}>
                      { subtopics? (
                        subtopics.map((subtopic, index) => (
                          <SubtopicCard subtopicData={subtopic} delAnswer={delAnswer} deleteQuestion={deleteQuestion} addQuestion ={addQuestion} editAnswer={editAnswer} sintetizeAnswers={sintetizeAnswers} answserQuestion={answserQuestion} addAnswer={addAnswer} />
                      ))
                      ):(null)}
                </Grid> 

                </Grid>
            </MediaQuery>
    
          </Container>
    </Box>
    </Box>
  );
}

